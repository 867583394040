// @ts-nocheck
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
    postJwtLogin,
} from "../../../helpers/galliumbackend_helper";
import { setAuthorization } from "helpers/api_helper";

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(postJwtLogin, {
            email: user.email,
            password: user.password,
        });
        setAuthorization(response.accessToken)
        localStorage.setItem("authUser", JSON.stringify(response));
        if (response) {
            yield put(loginSuccess(response));
            yield call(waitForAuthToken);
            history('/hypervisors');
        } else {
            yield put(apiError(response));
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser() {
    try {
        localStorage.removeItem("authUser");
        yield put(logoutUserSuccess(LOGOUT_USER, true));
        // @ts-ignore
        window.Intercom('shutdown');
    } catch (error) {
        yield put(apiError(LOGOUT_USER, error));
    }
}

function waitForAuthToken() {
    return new Promise(resolve => {
        const authToken = localStorage.getItem('authUser');
        if (authToken) {
            resolve();
        } else {
            const interval = setInterval(() => {
                const newAuthToken = localStorage.getItem('authUser');
                if (newAuthToken) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        }
    });
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
