import React from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    Col
} from "reactstrap"

import { map } from "lodash"

const VirtualMachineNetwork = ({ networkInterfaces }) => {

    return (
        <React.Fragment>
            <Col style={{ flex: '0 0 300px' }}>
                <Card>
                    <CardHeader className='align-items-center d-flex'>

                        <h4 className="card-title mb-0 flex-grow-1"><i className="mdi mdi-network-outline"></i> Virtual Network Connections</h4>

                    </CardHeader>
                    <CardBody className='mt-0 pt-0 mb-0 pb-0'>
                        <ul className="list-group list-group-flush border-dashed mb-0">
                            {map(networkInterfaces, (networkInterface, index) => (
                                <li className="list-group-item px-0" key={index}>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <span className="avatar-title p-1 bg-light-subtle rounded-circle">
                                                <i className='lab las la-ethernet la-2x text-secondary'></i>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-1">Interface {networkInterface.macAddress}</h6>

                                            <p className="text-muted fs-12 mb-0"><i className="mdi mdi-subdirectory-arrow-right"></i> Connected to: {networkInterface.networkName}</p>
                                            
                                        </div>
                                        <div className="flex-shrink-0 text-end">
                                            <h6 className="mb-1 text-muted">{networkInterface.intfType}</h6>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
};

export default VirtualMachineNetwork;