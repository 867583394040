import React, { useState } from 'react';

import {
    Col,
    Row,
    Button,
} from "reactstrap"

import DeleteModal from './ActionDeleteModal';
import { VirtualMachineDetail } from 'generated';

interface VirtualMachineActionDeleteProps {
    virtualMachine: VirtualMachineDetail
}

const VirtualMachineActionDelete: React.FC<VirtualMachineActionDeleteProps> = ({ virtualMachine }) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    
    return (
        <React.Fragment>
            <DeleteModal
                showModal={showDeleteModal}
                closeModal={() => {
                    setShowDeleteModal(false)
                }}
                virtualMachine={virtualMachine}
            />
            <Row>
                <Col md={8}>
                    <h5 className="card-title mb-3">Delete</h5>
                    <p className="text-muted">Remove this Virtual Machine</p>
                </Col>
                <Col md={4} className='text-end'>
                    <Button 
                        color="danger" 
                        className='mt-1' 
                        disabled={!virtualMachine.availableActions.destroy}
                        onClick={() => setShowDeleteModal(true)}
                    >Delete</Button>
                </Col>
            </Row>

        </React.Fragment>
    )
};

export default VirtualMachineActionDelete;