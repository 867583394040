import React from 'react';

import { Field, useFormikContext } from "formik";

import {
    Label,
    Col,
    Row,
} from "reactstrap"
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import { HostDetail } from 'generated';

const CPUMemory = ({ hypervisor }: {hypervisor: HostDetail}) => {
    const formik = useFormikContext();

    // TODO fix this garbage
    const availableCPUOptions = ["1", "2", "4", "6", "8", "10", "12", "14", "16"];
    
    const filteredCPUOptions = availableCPUOptions.filter((option) => parseInt(option, 10) <= hypervisor?.hwTotalCores);

    return (
        <React.Fragment>
            <GalliumFormHeader icon="cpu" title="CPU and Memory">

                <div className="mt-3 mb-3">
                    <Label className="form-label" htmlFor="key-data-input">Number of Virtual CPU Cores</Label>
                    <Row className='gy3'>
                        {filteredCPUOptions.map((item, key) => (
                            <Col md={4} lg={2} key={key}>
                                <div className="form-check card-radio">
                                    <Field
                                        id={"cpus"+key}
                                        name="cpus"
                                        value={item}
                                        type="radio"
                                        className="form-check-input"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor={"cpus"+key}
                                    >
                                        <span className="fs-14 mb-1 text-wrap d-block">
                                            {item}x vCPU
                                        </span>
                                    </Label>
                        
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                <Label className="form-label" htmlFor="key-data-input">Memory Allocation</Label>
                <Col lg={3} className='mb-3'>
                    <GalliumSizeInput
                        name="memory"
                    />

                </Col>
            </GalliumFormHeader>
        </React.Fragment>
    )
};

export default CPUMemory;
