//TODO Find the type safe progress bar
// @ts-nocheck

import React from 'react';

import { Link, useParams } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Button,
    Spinner,
    Progress,
    Row,
    Badge,

} from "reactstrap"

import StringPretty from 'Components/Gallium/TextHelpers';
import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';

type HypervisorStorageProps = {
  hypervisor: HostDetail;
};

const HypervisorStorage: React.FC<HypervisorStorageProps> = ({hypervisor}) => {
    const {t} = useTranslation()

    const storagePools = hypervisor.storagePools

    const hypervisorId = useParams().id;

    const storageCalculations ={
        storageAllocatedPercent: ((storagePools[0]?.allocatedMb / storagePools[0]?.totalMb) * 100),
        storageAvailablePercent: ((storagePools[0]?.freeMb / storagePools[0]?.totalMb) * 100),
        storageAllocatedGigabytes: (_.round(storagePools[0]?.allocatedMb / 1024, 0)),
        storageAvailableGigabytes: (_.round(storagePools[0]?.freeMb / 1024,0)),
    }

    return (

        <GalliumPageColumn>
            
            {hypervisor.pendingStorageCommand ? (
                <CardBody className='p-5 mt-5 mb-5 text-center'>
                    <Spinner color="primary" type="grow" />
                    <p>Storage Command in Progress</p>
                </CardBody>
            ):(
                <React.Fragment>
                    {storagePools[0] ? (
                        <Card className='mb-2'>
                            <CardHeader >
                                <Row>
                                    <Col md={6}>
                                        <h4 className="card-title mb-0">Primary Storage Pool</h4>
                                    </Col>
                                    <Col md={6} className='text-end'>
                                        <span className='badge border border-secondary text-secondary fs-6 me-2'><StringPretty string={storagePools[0]?.groupType} /></span>
                                        {storagePools[0]?.groupDetail[0].devices[0].healthy ? (
                                            <span className='badge border border-success text-success fs-6'>Healthy</span>
                                        ):(
                                            <span className='badge border border-danger text-danger fs-6'>Unhealthy</span>
                                        )}
                                    </Col>
                                </Row>
                                
                                
                            </CardHeader>
                            <CardBody >
                                <Progress multi className='progress-xl mt-2'>
                                    <Progress bar color="primary" value={storageCalculations.storageAllocatedPercent} />
                                    <Progress bar color="secondary-subtle" value={storageCalculations.storageAvailablePercent} />
                                </Progress>


                                <div className="mt-1 pt-2">
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <p className="text-truncate text-muted fs-14 mb-0"><i className="mdi mdi-circle align-middle text-primary me-2"></i>Allocated</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="mb-0">{storageCalculations.storageAllocatedGigabytes} GB</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <p className="text-truncate text-muted fs-14 mb-0"><i className="mdi mdi-circle align-middle text-secondary me-2"></i>Available</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <p className="mb-0">{storageCalculations.storageAvailableGigabytes} GB</p>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    ) : (
                        <CardBody className='p-5 mt-5 mb-5 text-center'>
                            {hypervisor?.online ?(
                                <Link
                                    to={`/hypervisors/${hypervisorId}/storage`}
                                    className="text-dark"
                                >
                                    <Button color="primary" className="btn-label"><i className="lab las la-plus-square label-icon"></i> Create Storage Pool</Button>
                                </Link>
                            ):(null)}
                        </CardBody>
                    )}
                </React.Fragment>
            )}

        </GalliumPageColumn>
    )
};

export default HypervisorStorage;