import { postJwtLogin } from "helpers/galliumbackend_helper";

import useSWRMutation from 'swr/mutation'

export const useLoginRequest = () => {
    const poster = (key, {arg}) => postJwtLogin(arg)

    const { trigger, error, isMutating } = useSWRMutation(`login`, poster);

    return { trigger, error, isMutating };
};

