import React, { useState } from "react";

import { Col, Row } from 'reactstrap';

import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';

import { useUserProfileUpdateRequest } from "GalliumAPIHooks/User/UserProfileHooks";
import { toast } from "react-toastify";
import ErrorAlert from "Components/Gallium/ErrorHelper";

const ChangePassword = () => {
    const { trigger: userProfileUpdateTrigger, isMutating } = useUserProfileUpdateRequest();

    const [errorObject, setErrorObject] = useState(undefined);

    const handlePasswordChangeFail = (error) => {
        changePasswordFormik.setErrors(error.response.data.errors || {})
        setErrorObject(error.response.data || {})
    }

    const handlePasswordChangeSuccess = () => {
        toast.success("Password Changed", { autoClose: 3000 });
        changePasswordFormik.resetForm()
    }

    const handlePasswordChangeRequest = (values: object) => {
        const options = {
            onError(error: object) {
                handlePasswordChangeFail(error)
            },
            onSuccess() {
                handlePasswordChangeSuccess()
            }
        }
        setErrorObject(undefined)
        userProfileUpdateTrigger(values, options);
    }

    const changePasswordFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            passwordCurrent: '',
            passwordNew: '',
            passwordNewConfirm: '',
        },
        validationSchema: Yup.object().shape({
            passwordCurrent: Yup.string()
                .required('Current password is required'),
            passwordNew: Yup.string()
                .required('New password is required'),
            passwordNewConfirm: Yup.string()
                .oneOf([Yup.ref('passwordNew'), null], 'Passwords must match')
                .required('Password confirmation is required'),
        }),
        onSubmit: (values) => {
            handlePasswordChangeRequest(values);
        }
    });

    return (
        <React.Fragment>
            <ErrorAlert errorObj={errorObject}/>
            <FormikProvider value={changePasswordFormik}>
                <Row className="g-2">
                    <Col lg={4}>
                        <Field
                            type="password"
                            id="passwordCurrent"
                            name="passwordCurrent"
                            label="Current Password"
                            component={GalliumInput}
                        />
                    </Col>

                    <Col lg={4}>
                        <Field
                            type="password"
                            id="passwordNew"
                            name="passwordNew"
                            label="New Password"
                            component={GalliumInput}
                        />
                    </Col>

                    <Col lg={4}>
                        <Field
                            type="password"
                            id="passwordNewConfirm"
                            name="passwordNewConfirm"
                            label="Confirm New Password"
                            component={GalliumInput}
                        />
                    </Col>
                    <Col lg={12} className="text-end mt-3">
                        <GalliumSubmitButton formik={changePasswordFormik} color="success" spinner={isMutating}>
                            Change Password
                        </GalliumSubmitButton>
                    </Col>
                </Row>
            </FormikProvider>
        </React.Fragment>
    );
};

export default ChangePassword;
