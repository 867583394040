import React from "react";
import StatusBadge from "../StatusBadge";

const ColStatusBadge = (cell) => {
    return (
        <React.Fragment>
            <StatusBadge status={cell.getValue()} />
        </React.Fragment>
    );
};

export default ColStatusBadge