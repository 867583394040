import React from "react";
import { Link } from "react-router-dom";
import StatusBadge from "Components/Gallium/StatusBadge";
import VMListControls from "./VirtualMachineControls";
import { round } from "lodash"


const Name = (cell) => {
    return (
        <React.Fragment>
            <Link to={"/vm/" + cell.row.original.slug} className="fw-medium link-primary text-wrap">{cell.getValue()}</Link>
        </React.Fragment>
    );
};

const Status = (cell) => {
    return (
        <React.Fragment>
            <StatusBadge status={cell.getValue()} />
        </React.Fragment>
    );
};

const Hypervisor = (cell) => {
    return (
        <React.Fragment>
            <Link to={"/hypervisors/" + cell.row.original.hostSlug}>
                {cell.getValue()}   
            </Link>
        </React.Fragment>
    );
};

const Controls = (cell) => {
    return (
        <React.Fragment>
            <div className="text-end">
                <VMListControls vm={cell.row.original}/>
            </div>
        </React.Fragment>
    );
};

const Resources = (cell) => {
    return (
        <React.Fragment>
            <span className="badge border border-primary text-primary me-2">{round(cell.row.original.memoryMb/1024,1)} GB</span><br />
            <span className="badge border border-primary text-primary">{cell.row.original.vcpus} vCPU</span>
        </React.Fragment>
    );
};

const Autostart = (cell) => {
    return (
        <React.Fragment>
            {cell.getValue() ?
                <span className="text-success"><i className="ri-checkbox-circle-line fs-17 align-middle"></i></span>
                :
                <span className="text-muted"><i className="ri-close-circle-line fs-17 align-middle"></i></span>
            }
        </React.Fragment>
    );
};

export { Name, Status, Hypervisor, Controls, Resources, Autostart};