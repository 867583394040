import React from 'react';

import FeatherIcon from 'feather-icons-react';

import {
    Col,
    Label,
    Input,
    FormFeedback
} from "reactstrap"

const TemplateBasics = ({ createTemplateFormik }) => {

    return (
        <React.Fragment>
            <div className="d-flex mb-3">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon="info" className={"text-info icon-dual-info icon-xs"} />
                </div>
                <div className="flex-grow-1">
                    <h5>Identification</h5>

                    <div className="mt-3 mb-3">

                        <Label className="form-label" htmlFor="name">Template Name</Label>
                        
                        <Col lg={4}>
                            <Input
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="Friendly Template Name"
                                type="text"
                                validate={{
                                    required: { value: true },
                                }}
                                onChange={createTemplateFormik.handleChange}
                                onBlur={createTemplateFormik.handleBlur}
                                value={createTemplateFormik.values?.name || ""}
                                invalid={
                                    createTemplateFormik.touched?.name && createTemplateFormik.errors?.name ? true : false
                                }
                            />
                            {createTemplateFormik.touched?.name && createTemplateFormik.errors?.name ? (
                                <FormFeedback type="invalid">{createTemplateFormik.errors?.name}</FormFeedback>
                            ) : null}
                        </Col>
                        

                    </div>

                    <div className="mt-3 mb-3">

                        <Label className="form-label" htmlFor="key-data-input">Description</Label>

                        <Input
                            name="description"
                            id="description"
                            className="form-control"
                            placeholder="A description of this Template"
                            type="textarea"
                            onChange={createTemplateFormik.handleChange}
                            onBlur={createTemplateFormik.handleBlur}
                            value={createTemplateFormik.values?.description || ""}
                            invalid={
                                createTemplateFormik.touched?.description && createTemplateFormik.errors?.description ? true : false
                            }
                        />
                        {createTemplateFormik.touched?.description && createTemplateFormik.errors?.description ? (
                            <FormFeedback type="invalid">{createTemplateFormik.errors?.description}</FormFeedback>
                        ) : null}
                    
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default TemplateBasics;
