import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Nav, 
    NavItem, 
    NavLink,
    TabContent, 
    TabPane,
    Button
} from "reactstrap"

import classnames from "classnames";

import VirtualMachineSidebar from './Sidebar';
import VirtualMachineSubmitted from './Submitted';
import VirtualMachineStorage from './Storage';
import VirtualMachineNetwork from './Network';
import VirtualMachineActions from './Actions';
import VirtualMachineEdit from '../Edit';
import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import VMListControls from 'Components/Gallium/VM/VirtualMachineControls';

const VirtaulMachineDetail = () => {
    const virtualMachineId = useParams().id;

    const {data: virtualMachine, isLoading, error} = useGetVirtualMachine(virtualMachineId, 1500)


    // Nav Bar Settings
    const [searchParams] = useSearchParams()
    const [galliumNavTab, setgalliumNavTab] = useState(searchParams.get("tab") || "1");
    const galliumNavToggle = (tab) => {
        if (galliumNavTab !== tab) {
            setgalliumNavTab(tab);
        }
    };

    const tabs = (
        <Nav className="nav-tabs-custom border-bottom-0 pt-1" role="tablist">
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "1", })} onClick={() => { galliumNavToggle("1"); }} >
                    Console
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "2", })} onClick={() => { galliumNavToggle("2"); }} >
                    Storage
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "3", })} onClick={() => { galliumNavToggle("3"); }} >
                    Network
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "ACTIONS", })} onClick={() => { galliumNavToggle("ACTIONS"); }} >
                    Actions
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "SETTINGS", })} onClick={() => { galliumNavToggle("SETTINGS"); }} >
                    Settings
                </NavLink>
            </NavItem>
        </Nav>
    )

    document.title = "Virtual Machine | Gallium";
    const crumbs = [
        {
            name: "Virtual Machines",
            link: "/vm"
        },
        {
            name: "View Virtual Machine",
        }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                        <React.Fragment>
                            <GalliumBreadcrumb title={virtualMachine?.name} crumbs={crumbs} tabs={tabs}>
                                <VMListControls vm={virtualMachine} />
                            </GalliumBreadcrumb>
                            <Row>
                                <VirtualMachineSidebar data={virtualMachine}/>
                                <Col>
                                    {virtualMachine.status === "submitted" || virtualMachine.status === "pending" ? (
                                        <VirtualMachineSubmitted command={virtualMachine.inProgressCommand}/>
                                    ):(
                                        <React.Fragment>

                                            <TabContent activeTab={galliumNavTab} className="text-muted">
                                                <TabPane tabId="1" id="nav-light-home">
                                                    <Card className='card-dark'>

                                                        <CardBody className='p-5 mt-5 mb-5 text-center'>
                                                            {virtualMachine.availableActions.console ? (
                                                                <Link to="connect">
                                                                    <Button outline color="light" className="btn-label"><i className="bx bx-desktop label-icon"></i> Connect</Button>
                                                                </Link>
                                                            ):(
                                                                <Button disabled outline color="light" className="btn-label"><i className="bx bx-desktop label-icon"></i> Not Available</Button>
                                                            )}
                                                        </CardBody>

                                                    </Card>
                                                </TabPane>  

                                                <TabPane tabId="2" id="nav-light-home">
                                                    <VirtualMachineStorage disks={virtualMachine.disks} virtualMachine={virtualMachine}/>
                                                </TabPane>
                                    
                                                <TabPane tabId="3" id="nav-light-home">
                                                    <VirtualMachineNetwork networkInterfaces={virtualMachine.networkInterfaces}/>
                                                </TabPane>
                                                <TabPane tabId="ACTIONS" id="nav-light-home">
                                                    <VirtualMachineActions virtualMachine={virtualMachine}/>
                                                </TabPane>
                                                <TabPane tabId="SETTINGS" id="nav-light-home">
                                                    <VirtualMachineEdit />
                                                </TabPane>
                                            </TabContent>
                                        </React.Fragment>
                                    )}
                                </Col>
                
                            </Row>
                        </React.Fragment>
                    )}
                </Container> 
            </div>
        </React.Fragment>
    )
}

export default VirtaulMachineDetail;