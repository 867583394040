import React from 'react';

import { Field } from "formik";

import FeatherIcon from 'feather-icons-react';


import {
    Label,
    Col,
    Input,
    FormFeedback
} from "reactstrap"

const TemplateSettings = ({ createTemplateFormik }) => {
    
    return (
        <React.Fragment>
            <div className="d-flex mb-3">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon="settings" className={"text-info icon-dual-info icon-xs"} />
                </div>
                
                <div className="flex-grow-1">
                    <h5>Settings</h5>
                    <Label className="form-label" htmlFor="key-data-input">Minimum Memory</Label>
                    <Col lg={3}>
                        <div className="mb-3 input-group">
                            <Input
                                name="memory"
                                id="memory"
                                className="form-control"
                                placeholder="Minimum"
                                type="text"
                                validate={{
                                    required: { value: true },
                                }}
                                onChange={createTemplateFormik.handleChange}
                                onBlur={createTemplateFormik.handleBlur}
                                value={createTemplateFormik.values?.memory || ""}
                                invalid={
                                    createTemplateFormik.touched?.memory && createTemplateFormik.errors?.memory ? true : false
                                }
                            />
                            <Field
                                id="memoryMultiplier"
                                name="memoryMultiplier"
                                as="select"
                                className="input-group-text"
                                onChange={createTemplateFormik.handleChange}
                                onBlur={createTemplateFormik.handleBlur}
                            >
                                <option value="1">MB</option>
                                <option value="1024">GB</option>
                            </Field>
                            {createTemplateFormik.touched?.memory && createTemplateFormik.errors?.memory ? (
                                <FormFeedback type="invalid">{createTemplateFormik.errors?.memory}</FormFeedback>
                            ) : null}
                        </div>

                        {/* <Label className="form-label">Options</Label>
                        <div className="form-check form-switch mb-2">
                            <Label className="form-check-label mx-2 align-middle" htmlFor="cloud-init-switch">Enable Cloud-init</Label>
                            <Input 
                            name="supportsCloudInit" 
                            className="form-check-input form-switch-md" 
                            type="checkbox" 
                            role="switch" 
                            id="cloud-init-switch" 
                            onChange={createTemplateFormik.handleChange}
                            onBlur={createTemplateFormik.handleBlur}
                            value={createTemplateFormik.values?.supportsCloudInit || false}/>
                            
                        </div> */}
                    </Col>
                </div>
            </div>
        </React.Fragment>
    )
};

export default TemplateSettings;
