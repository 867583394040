import React, { useState } from "react";

import { Button, Label,  Modal,  ModalBody, ModalHeader } from "reactstrap";

import { Col } from 'reactstrap';

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { GalliumApiSuccessResponse, GalliumApiErrorResponse, VirtualMachineDetail, HostVolume, ReconfigureVmRequest, ConfigureVmDiskAction } from "generated";
import { useGetHostVolumes } from "GalliumAPIHooks/HostVolume/HostVolumeHooks";
import { useTranslation } from "react-i18next";
import Loader from "Components/Gallium/Loader";
import ErrorNotice from "Components/Gallium/ErrorNotice";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";

interface AttachDiskModalFormProps {
    closeModal: () => void,
    candidateVolumes: Array<HostVolume>,
    virtualMachine: VirtualMachineDetail
}

const AttachDiskModalForm:React.FC<AttachDiskModalFormProps> =({candidateVolumes, virtualMachine, closeModal}) => {
    const {t} = useTranslation()   
    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState(null);

    const handleAttachDiskChangeFail = (error: GalliumApiErrorResponse) => {
        diskAttachFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAttachDiskChangeSuccess = (data: GalliumApiSuccessResponse) => {
        diskAttachFormik.resetForm()
        closeModal()
    }

    const handleAttachDiskRequest = (values: ReconfigureVmRequest) => {
        const options = {
            onError(error: object) {
                handleAttachDiskChangeFail(error)
            },
            onSuccess(data: GalliumApiSuccessResponse) {
                handleAttachDiskChangeSuccess(data)
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const diskAttachFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            disks: [
                {
                    diskSlug: "",
                    action: ConfigureVmDiskAction.ATTACH
                }
            ]
        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values) => {
            handleAttachDiskRequest(values);
        }
    });

    return (
        <React.Fragment>
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={diskAttachFormik}>

                    <Label>{t('translation:virtualMachine:diskAttachField')}</Label>
                    <Field
                        id="diskAttachField"
                        name={`disks[${0}].diskSlug`}
                        as="select"
                        className="form-select mb-3"
                        
                    >
                        <option>Please Select Disk</option>
                        {candidateVolumes?.map((volume, key) => (
                            <>
                                <option value={volume.slug} key={key}>{volume.name}</option>
                            </>
                        ))
                        }
                    </Field>

                

                    <Col lg={12} className="text-end mt-3">
                        <GalliumSubmitButton formik={diskAttachFormik} color="success" spinner={isMutating}>
                            {t('translation:virtualMachine:diskAttach')}
                        </GalliumSubmitButton>
                    </Col>

                </FormikProvider>
            </ModalBody>
        </React.Fragment>
    )
}

interface AttachDiskModalProps {
    showModal: boolean,
    closeModal: () => void,
    virtualMachine: VirtualMachineDetail
}

const AttachDiskModal: React.FC<AttachDiskModalProps> = ({ showModal, closeModal, virtualMachine }) => {
    const {t} = useTranslation()
    const {data, error, isLoading} = useGetHostVolumes(virtualMachine.host.slug)
    const unattachedVolumes = data?.volumes.filter(volume => !volume.attached) || [];

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t('translation:virtualMachine:diskAttach')}
            </ModalHeader>      
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                
                unattachedVolumes.length !== 0 ? (
                    
                    <AttachDiskModalForm candidateVolumes={unattachedVolumes} virtualMachine={virtualMachine} closeModal={closeModal}/>

                ):(
                    <ModalBody className="p-5 m-5 text-center">
                        <Button disabled outline color="secondary">
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-slash"]} className='me-2'/>
                            {t('translation:virtualMachine:diskAttachNoCandidate')}
                        </Button>
                    </ModalBody>
                )
            )}
        </Modal>
    );
};

export default AttachDiskModal


