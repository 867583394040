import { all, fork } from "redux-saga/effects"

//layout
import LayoutSaga from "./layouts/saga";

//public
import AuthSaga from "./auth/login/saga"
import TemplatesSaga from "./templates/saga";

import accountSaga from "./auth/register/saga";


export default function* rootSaga() {
    yield all([
    //public
        fork(LayoutSaga),
        fork(AuthSaga),
        fork(TemplatesSaga),
        fork(accountSaga)
    ])
}
