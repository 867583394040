import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";

import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import { GalliumApiErrorResponse, VirtualMachineDetail } from 'generated';
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDestroyVirtualMachine } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import LordIcon from "Components/Gallium/LordIcon";
import trashIcon from "assets/lordicon/trash.json"

interface DeleteModalProps {
    showModal: boolean,
    closeModal: () => void,
    virtualMachine: VirtualMachineDetail
}

const DeleteModal: React.FC<DeleteModalProps> = ({ showModal, closeModal, virtualMachine }) => {

    const [errorObject, setErrorObject] = useState(undefined)

    const navigate = useNavigate();

    const { 
        trigger: triggerDestroyVirtualMachine, 
        isMutating,
    } = useDestroyVirtualMachine(virtualMachine.slug)


    const handleDestroyVirtualMachineFail = (error: GalliumApiErrorResponse) => {
        destroyVirtualMachineFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleDestroyVirtualMachineSuccess = () => {
        toast.info("Delete Requested")
        navigate("./hypervisors")
    }

    const handleDestroyVirtualMachineRequest = () => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleDestroyVirtualMachineFail(error)
            },
            onSuccess() {
                handleDestroyVirtualMachineSuccess()
            }
        }
        setErrorObject(undefined)
        triggerDestroyVirtualMachine(null, options);
    }

    // Formik has been left in this component in case a confirmation code or simliar is required in the future.
    const destroyVirtualMachineFormik = useFormik({
        initialValues: {

        },
        validationSchema: Yup.object({
            
        }),
        onSubmit: () => {
            handleDestroyVirtualMachineRequest();
        }
    });

    // Reset this modal on open/close
    useEffect(() => {
        destroyVirtualMachineFormik.resetForm();
        setErrorObject(undefined)
    }, [showModal]);


    return (
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>

            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                Delete Virtual Machine
            </ModalHeader>  
            <ModalBody>
                <ErrorAlert errorObj={errorObject} />
                <div className="text-center">
                    <LordIcon icon={trashIcon} />
                    <div className="pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Are you sure ?</h4>
                        <p className="text-muted mx-4 mb-0">
              This will delete {virtualMachine.name || "this virtual Machine "} and detach all disks. 
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <GalliumSubmitButton formik={destroyVirtualMachineFormik} spinner={isMutating} color="danger">
                        Delete
                    </GalliumSubmitButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DeleteModal;