import React from 'react';

import { FieldArray, useFormikContext } from "formik";

import {
    Card,
    CardBody,
    Col,
    Row,

} from "reactstrap"

import { round } from 'lodash';
import GalliumSizeInput from 'Components/Gallium/GalliumSizeInput';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';

const Disks = ({ hypervisor }) => {
    const formik = useFormikContext();
    const storageRequired = formik.values["disks"]?.reduce((total, obj) => total + obj.size, 0);

    return (
        <GalliumFormHeader icon="hard-drive" title="Disks">
            <FieldArray
                name="disks"
                render={arrayHelpers => (
                    formik.values["disks"]?.length > 0 ? (formik.values["disks"]?.map((disk, index) => (
                        <Card className='mb-1' key={index}>
                            <CardBody className="pb-3">
                                <Row className='g-3' >
                                    <Col lg={8} md={6}>
                                        <p className="text-muted mb-0">
                                            {disk.diskType === "DISK" ? (
                                                <React.Fragment>
                                                    <i className="lab las la-hdd"></i> Disk {index + 1}
                                                </React.Fragment>
                                            ) : ("ISO")}
                                        </p>
                                        <p className="small text-muted mb-0 text-truncate">
                                            {disk.diskType === "DISK" ?
                                                (disk.filename ? ("Template Disk"):("Blank Disk")):
                                                (disk.filename)
                                            }
                                        </p>

                                    </Col>
                                    {disk.diskType === "DISK" ? (
                                        <Col lg={4} md={6}>
                                            <GalliumSizeInput name={`disks.${index}.size`} />
                                        </Col>
                                    ):(null)}
                                </Row>
                            </CardBody>
                        </Card>
                    ))):(null)
                )}
            />

            <div className="mt-3">
                <Card>
                    <CardBody>
                        <Row className='g-3' >
                            <Col lg={6}>
                                <p className="text-muted mb-2">Storage Pool</p>
                                <select className="form-select">
                                    <option>Primary Storage Pool: {round(hypervisor?.storagePools[0]?.freeMb / 1024,0)}GB Free</option>
                                </select>
                            </Col>
                            <Col lg={6}>
                                <div className='float-end'>
                                    <p className="text-muted mb-2">Required Storage</p>
                                    <h5 className="mb-0 mt-1 float-end fs-3" >{round(storageRequired / 1024,)} GB</h5>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </GalliumFormHeader>
    )
};

export default Disks;
