


import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ToInitials from './ToInitials';
import { setAuthorization } from '../../helpers/api_helper';
import { useRefreshAccessToken } from '../../GalliumAPIHooks/User/UserHooks';
import { GalliumTokenRequest } from '../../generated/models/GalliumTokenRequest';
import FeatherIcon from "feather-icons-react";


const OrgPicker = () => {

    const {trigger} = useRefreshAccessToken()

    // Get org name from local storage
    const [orgName, setOrgName] = useState("Organisation");
    const [availableOrgs, setAvailableOrgs] = useState([]);
    const [refreshToken, setRefreshToken] = useState('');
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setOrgName(obj.org.name === undefined ? "Organisation" : obj.org.name);
            setAvailableOrgs(obj.availableOrgs)
            setRefreshToken(obj.refreshToken)
        }
    }, []);

    const [showViewModal, setShowViewModal] = useState<boolean | undefined>(false);

    const toggle = useCallback(() => {
        if (showViewModal) {
            setShowViewModal(false);
        } else {
            setShowViewModal(true);
        }
    }, [showViewModal]);


    const handleChangeOrgFail = (error) => {
        console.log(error)
    }

    const handleChangeOrgSuccess = (data) => {
        const updatedData = {
            ...data,
            refreshToken: refreshToken
        };

        setAuthorization(updatedData.accessToken)
        localStorage.setItem("authUser", JSON.stringify(updatedData))
        window.location.href = '/';
    }

    const handleChangeOrgRequest = (newOrgSlug: string) => {
        const options = {
            onError(error: object) {
                handleChangeOrgFail(error)
            },
            onSuccess(data) {
                handleChangeOrgSuccess(data)
            },
            revalidate: false
        }
        const requestBody: GalliumTokenRequest = {
            refreshToken: refreshToken,
            orgSlug: newOrgSlug
        }
        trigger(requestBody, options);
    }

    return (
        <React.Fragment>

            <Modal
                isOpen={showViewModal}
                toggle={toggle}
                centered
                size="md"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-secondary-subtle" toggle={toggle}>
                Switch Organisation
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row className="g-3">
                        <Col lg={12}>
                            {availableOrgs.map((value, index) => (
                                <Button color="soft-primary" className="w-100 mb-3" key={index} onClick={() => handleChangeOrgRequest(value.slug)}>
                                    <span className="d-flex align-items-center">
                                        <div className="avatar-sm">
                                            <div className="avatar-title rounded bg-primary text-white">
                                                {ToInitials(value.name)}
                                            </div>
                                        </div>
                                        <span className="text-start ms-xl-2">
                                            <span className="d-inline-block ms-2 fs-16 fw-medium">{value.name}</span>
                                            <span className="d-block ms-2 fs-12 opacity-75">{value.slug}</span>
                                        </span>
                                    </span>
                                </Button>
                            
                            ))}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <li className="menu-title"><span data-key="t-menu">Organisation</span></li>
            <li className="nav-item">
                <Link
                    onClick={(e) => {
                        e.preventDefault()
                        toggle()
                    }}
                    className="nav-link menu-link"
                    to="/#"
                    data-bs-toggle="collapse"
                >
                    <FeatherIcon icon="globe" className="icon-dual" /> <span data-key="t-apps">{orgName}</span>
                </Link>
            </li>
        </React.Fragment>
    )
};

export default OrgPicker;