import React, { useState } from 'react';


import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import {
    Label,
    Col,
    Row,
    Alert,
} from "reactstrap"

import { ApiVmStatus, GalliumApiErrorResponse, VirtualMachineDetail } from 'generated';

import { useModifyVirtualMachineConfig } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';

import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { toast } from 'react-toastify';
import ErrorAlert from 'Components/Gallium/ErrorHelper';


const EditHardware = ({ virtualMachine }: {virtualMachine: VirtualMachineDetail}) => {

    const {trigger, isMutating} = useModifyVirtualMachineConfig(virtualMachine.slug)

    const { data: hypervisor, error: hypervisorError, isLoading: isHypervisorLoading } = useGetHost(virtualMachine.host.slug);

    const [errorObject, setErrorObject] = useState(undefined)

    const handleVirtualMachineChangeFail = (error: GalliumApiErrorResponse) => {
        editVirtualMachineHardwareFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleVirtualMachineChangeSuccess = () => {
        toast.info("Submitted Virtual Machine Change")
    }

    const handleVirtualMachineChangeRequest = (values) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachineChangeFail(error)
            },
            onSuccess() {
                handleVirtualMachineChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik
    const editVirtualMachineHardwareFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            memory: virtualMachine.memoryMb,
            memoryMultiplier: "1",
            cpus: virtualMachine.cpus.toString(),
        },
        validationSchema: Yup.object({
            memory: Yup.number()
                .required('Memory is required')
                .test('memory-test', 'Memory must be between 128mb and 128gb', function(value) {
                    const { memoryMultiplier } = this.parent;
                    const totalMemory = value * memoryMultiplier;
                    return totalMemory >= 128 && totalMemory <= 131072;
                }),
            memoryMultiplier: Yup.number().required('Memory Multiplier is missing'),
            cpus: Yup.number().required('CPUs are required')
        }),
        onSubmit: (values) => {

            const editValues = {
                cpus: values["cpus"],
                // TODO replace with size component
                // @ts-ignore
                memoryMb: values["memory"] * values["memoryMultiplier"],
            };
            // @ts-ignore
            window.Intercom('trackEvent', 'edit-vm-request');
            handleVirtualMachineChangeRequest(editValues)
        },
    });

    const pageDisabled: boolean = virtualMachine.status === ApiVmStatus.STOPPED ? false : true

    const availableCPUOptions = ["1", "2", "4", "8", "16"];
    
    const filteredCPUOptions = availableCPUOptions.filter((option) => parseInt(option, 10) <= hypervisor?.availCpus, 10);

    return (
        <React.Fragment>
            {isHypervisorLoading ? (<Loader />) : hypervisorError ? (<ErrorNotice />) : (
                <FormikProvider value={editVirtualMachineHardwareFormik}>
                    {pageDisabled ? (<Alert color="info">You cannot edit these settings whilst the Virtual Machine is in the current state</Alert>):(null)}
                    <ErrorAlert errorObj={errorObject} />

                    <div className="mt-0 mb-3">
                        <Label className="form-label" htmlFor="key-data-input">Number of Virtual CPU Cores</Label>
                        <Row className='gy3'>
                            {filteredCPUOptions.map((item, key) => (
                                <Col lg={4} md={6} sm={12} key={key}>
                                    <div className="form-check card-radio">
                                        <Field
                                            id={"cpus"+key}
                                            name="cpus"
                                            value={item}
                                            type="radio"
                                            className="form-check-input"
                                            disabled={pageDisabled}
                                        />
                                        <Label
                                            className="form-check-label"
                                            htmlFor={"cpus"+key}
                                                    
                                        >
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                                {item}x vCPU
                                            </span>
                                        </Label>
                                    
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <Label className="form-label" htmlFor="key-data-input">Memory Allocation</Label>
                    <Col lg={6}>
                        <div className="mb-3 input-group">
                            <Field
                                name="memory"
                                id="memory"
                                className="form-control"
                                placeholder="The amount of memory in megabytes for this VM"
                                type="text"
                                disabled={pageDisabled}
                            />
                            <Field
                                id="memoryMultiplier"
                                name="memoryMultiplier"
                                as="select"
                                className="input-group-text"

                                disabled={pageDisabled}
                            >
                                <option value="1">MB</option>
                                <option value="1024">GB</option>
                            </Field>
                        </div>
                    </Col>
                    {pageDisabled ? (null):(
                        <div className='float-end'>
                            <GalliumSubmitButton formik={editVirtualMachineHardwareFormik} spinner={isMutating} color="success"> Submit</GalliumSubmitButton>
                        </div>
                    )}

                </FormikProvider>
            )}
        </React.Fragment>
    )
};

export default EditHardware;
