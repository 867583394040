import React, { useState } from 'react';

import {
    Button
} from "reactstrap"

import NetworkEditModal from './NetworkEditModal';
import { HostDetail, VmNetwork } from 'generated';
import { useTranslation } from 'react-i18next';

interface NetworkEditButtonProps {
    hypervisor: HostDetail
    adaptor: VmNetwork
}

const NetworkEditButton: React.FC<NetworkEditButtonProps> = ({ hypervisor, adaptor }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    return (
        <React.Fragment>
            <NetworkEditModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                hypervisor={hypervisor}
                adaptor={adaptor}
            />
            
            <Button color="secondary" className='mt-1 btn-sm btn-block btn-soft-secondary' onClick={() => setShowModal(true)}>
                <i className="mdi mdi-pencil-outline me-1" />
                {t('translation:common:edit')}
            </Button>


        </React.Fragment>
    )
};

export default NetworkEditButton;