import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row, Form } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { usePostHog } from 'posthog-js/react'


// Formik loginFormik
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";


//import images
import logoLight from "assets/images/logo-light.png";

import { useLoginRequest } from 'Components/Hooks/LoginHooks';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { GalliumLoginResponse } from 'generated';
import { setAuthorization } from 'helpers/api_helper';

const Login = () => {
    const posthog = usePostHog()

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const { trigger, error: swrError, isMutating } = useLoginRequest()

    const [errorObject, setErrorObject] = useState(undefined)
    const [mfaPrompt, setMfaPrompt] = useState(false)


    const handleLoginFail = (error) => {
        loginFormik.setErrors(error.response.data.errors || {})
        setErrorObject(error.response.data || {})
    }

    const handleLoginSuccess = (data: GalliumLoginResponse) => {
        if (data.mfaRequired) {
            setMfaPrompt(true)
        } else {
            setAuthorization(data.accessToken)
            localStorage.setItem("authUser", JSON.stringify(data))
            posthog?.identify(data.user.email,{name: data.user.name })
            navigate("./hypervisors")
        }
    }

    const handleLoginRequest = (values: object) => {
        const options = {
            onError(error: object) {
                handleLoginFail(error)
            },
            onSuccess(data: GalliumLoginResponse) {
                handleLoginSuccess(data)
            },
            revalidate: false
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const loginFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: searchParams.get("email") || '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            handleLoginRequest(values);
        }
    });

    document.title = "Sign In | Gallium";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="45" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to the Gallium console.</p>
                                        </div>

                                        <ErrorAlert errorObj={errorObject} />

                                        <div className="p-2 mt-4">
                                            <FormikProvider value={loginFormik}>
                                                <Field
                                                    label="Email"
                                                    name="email"
                                                    id="email"
                                                    className="mb-3"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    component={GalliumInput}
                                                />

                                                <Field
                                                    label="Password"
                                                    name="password"
                                                    id="password"
                                                    type="password"
                                                    className="mb-3"
                                                    placeholder="Enter Password"
                                                    component={GalliumInput}
                                                />

                                                {mfaPrompt ?(
                                                    <Field
                                                        label="Multi Factor Code"
                                                        name="otp"
                                                        id="otp"
                                                        type="text"
                                                        className="pe-5"
                                                        placeholder="Enter the code from your Authenticator"
                                                        component={GalliumInput}
                                                    />
                                                ):(null)}
                    
                                                <div className="mt-4 text-end">
                                                    <GalliumSubmitButton formik={loginFormik} color="success" spinner={isMutating}>
                                                        Sign In
                                                    </GalliumSubmitButton>
                                                </div>
                                            </FormikProvider>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <Link to="/passwordreset" className="text-primary"> Forgot Password? </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Login