import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";

import React, { useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GalliumApiErrorResponse, HostDetail, UpdateRequestType } from "generated";
import { UpdateRequest, useUpdateHostOs } from "GalliumAPIHooks/Host/HostHooks";
import LordIcon from "Components/Gallium/LordIcon";
import WarningIcon from "assets/lordicon/warning.json"


interface UpdateModalProps {
    showModal: boolean,
    closeModal: () => void,
    hypervisor: HostDetail
}

const UpdateModal: React.FC<UpdateModalProps> = ({ showModal, closeModal, hypervisor }) => {
    const { t } = useTranslation()

    const [errorObject, setErrorObject] = useState(undefined)

    const { 
        trigger, 
        isMutating,
    } = useUpdateHostOs(hypervisor.slug)


    const handleHostUpdateFail = (error: GalliumApiErrorResponse) => {
        hostUpdateFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleHostUpdateSuccess = () => {
        toast.success("Update Started")
        closeModal()
    }

    const handleHostUpdateRequest = (values: UpdateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleHostUpdateFail(error)
            },
            onSuccess() {
                handleHostUpdateSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const hostUpdateFormik = useFormik({
        initialValues: {
            reboot: true,
            requestType: UpdateRequestType.UPDATE_AND_CONFIRM,
        },
        validationSchema: Yup.object({
            reboot: Yup.boolean().required("Reboot is required"),
            hostUpdateAction: Yup.string().oneOf(Object.values(UpdateRequestType), "Invalid host update action"),
        }),
        onSubmit: (values: UpdateRequest) => {
            handleHostUpdateRequest(values);
        }
    });

    return (
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>

            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t("hypervisor.update.updateModalHeader")}
            </ModalHeader>  
            <ModalBody className="py-3 px-5">
                <div className="modal-body text-center p-2">
                    <LordIcon icon={WarningIcon} />
                    <div className="mt-1 pt-1">
                        <h4>Apply Update</h4>
                        <p className="text-muted">
                            This will apply update this Hypervisor to verison {hypervisor.hostOsVersionAvailable}. During the update all Virtual Machines will be paused and the server will reboot. The time taken depends on the hardware. Virtual Machines will resume once the update is complete.
                        </p>
                    </div>
                </div>
                <ErrorAlert errorObj={errorObject} />
                <FormikProvider value={hostUpdateFormik}>
                    <Row>
                        {/* <Col mb={3}>
                            <div className="form-check form-switch form-switch-lg form-switch-warning" dir="ltr">                       
                                <Field
                                    name="force"
                                    id="force"
                                    type="checkbox"
                                    className="form-check-input"
                                />
                                <Label className="form-check-label" htmlFor="force">{t("hypervisor.update.updateReboot")}</Label>  
                            </div>
                            <p className="text-muted">{t("hypervisor.update.updateRebootDetail")}</p>
                        </Col> */}
                    </Row>
                </FormikProvider>
                <div className="d-flex gap-2 float-end mt-4 mb-2">
                    <GalliumSubmitButton formik={hostUpdateFormik} spinner={isMutating} color="warning">
                        {t("hypervisor.update.updateAction")}
                    </GalliumSubmitButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default UpdateModal;