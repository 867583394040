import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";

import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import { GalliumApiErrorResponse, HostDetail } from 'generated';
import { useArchiveHost } from 'GalliumAPIHooks/Host/HostHooks';
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PlayOnce from "Components/Gallium/LordIcon";
import ServerIcon from 'assets/lordicon/wired-outline-988-archive.json';
import LordIcon from "Components/Gallium/LordIcon";


interface ArchiveModalProps {
    showModal: boolean,
    closeModal: () => void,
    hypervisor: HostDetail
}

const ArchiveModal: React.FC<ArchiveModalProps> = ({ showModal, closeModal, hypervisor }) => {
    

    const [errorObject, setErrorObject] = useState(undefined)

    const navigate = useNavigate();

    const { 
        trigger: triggerArchiveHost, 
        isMutating,
    } = useArchiveHost(hypervisor.slug)


    const handleArchiveHostFail = (error: GalliumApiErrorResponse) => {
        archiveHostFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleArchiveHostSuccess = () => {
        toast.success("Hypervisor Archived")
        navigate("./hypervisors")
    }

    const handleArchiveHostRequest = () => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleArchiveHostFail(error)
            },
            onSuccess() {
                handleArchiveHostSuccess()
            }
        }
        setErrorObject(undefined)
        triggerArchiveHost(null, options);
    }

    // Formik has been left in this component in case a confirmation code or simliar is required in the future.
    const archiveHostFormik = useFormik({
        initialValues: {

        },
        validationSchema: Yup.object({
            
        }),
        onSubmit: () => {
            handleArchiveHostRequest();
        }
    });


    return (
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>

            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                Archive Hypervisor
            </ModalHeader>  
            <ModalBody>
                <ErrorAlert errorObj={errorObject} />
                <div className="text-center">
                    <LordIcon icon={ServerIcon} />
                    <div className="pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Are you sure ?</h4>
                        <p className="text-muted mx-4 mb-0">
              This will mark {hypervisor.name || "this hypervisor "} as inactive and hide it from the Console. It will reappear if you reboot the Hypervisor in the future. 
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <GalliumSubmitButton formik={archiveHostFormik} spinner={isMutating} color="danger">
                        Archive
                    </GalliumSubmitButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ArchiveModal;