import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import TableContainer from '../../../Components/Gallium/TableContainerNew';

import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"

import {
    Hypervisor,
    Mappings,
} from "./SGListCol";
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useDeleteServiceGateway, useGetListServiceGateways } from 'GalliumAPIHooks/ServiceGateway/ServiceGatewayHooks';
import FeatureInfo from './FeatureInfo';
import { ServiceGateway } from 'generated';
import DeleteModal from 'Components/Common/DeleteModal';

const ServiceGatewayListComponent = () => {
    const { data, error, isLoading } = useGetListServiceGateways()

    const { trigger: deleteSgTrigger, isMutating } = useDeleteServiceGateway()

    const [selectedKey, setSelectedKey] = useState<ServiceGateway | undefined>(undefined);  

    // Delete SSH Key Modal
    const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(false);
    
    const onClickDelete = (key: ServiceGateway) => {
        setSelectedKey(key);
        setShowDeleteModal(true);
    };

    const handleDeleteSSHKey = () => {
        if (selectedKey) {
            deleteSgTrigger(selectedKey);
            setShowDeleteModal(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <span className="fw-medium">{cellProps.getValue()}</span>
                        </React.Fragment>
                    );
                },
            },{
                header: "Hypervisor",
                accessorKey: "mappings",
                id: "hypervisor",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <Hypervisor {...cellProps} />;
                },
            },{
                header: "Mappings",
                accessorKey: "mappings",
                enableColumnFilter: false,
                className: "col-5",
                cell: (cellProps) => {
                    return <Mappings {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-1",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <div className='text-end'>
                                <Link to="#" className="remove-item-btn" onClick={() => {onClickDelete(cellProps.row.original) }}>
                                    <span className='text-muted text'>
                                        <i className="ri-delete-bin-5-line"></i>
                                    </span>
                                </Link>
                            </div>
                        </React.Fragment>
                    )
                },
            },
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'name',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={showDeleteModal}
                onDeleteClick={handleDeleteSSHKey}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={selectedKey?.name || "Unknown"}
                isWorking={isMutating}
            />

            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Row>
                    <Col lg={12}>
                        <Card id="serviceGatewayList">
                            <CardBody className="pt-0">
                                <TableContainer
                                    columns={columns}
                                    data={(data.serviceGateways || [])}
                                    isGlobalFilter={true}
                                    customPageSize={20}
                                    sorting={sorting}
                                    divClass="table-responsive table-card mb-3"
                                    tableClass="align-middle table-nowrap mb-0"
                                    theadClass="table-light table-nowrap"
                                    thClass="table-light text-muted"
                                    SearchPlaceholder='Search for a Service Gateway'
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}            
                
        </React.Fragment>
    );
};

export default ServiceGatewayListComponent;