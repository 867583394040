import { TemplateResponse, TemplateService } from "generated";
import useSWR from "swr";

export const useListTemplates = (includeDraft?: boolean) => {
    const listTemplates = () => TemplateService.listTemplates(includeDraft)
    
    const { data, error, isLoading } = useSWR<TemplateResponse>(`templates`, listTemplates, );

    return { data, error, isLoading };
};
