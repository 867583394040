import React from 'react';

import FeatherIcon from 'feather-icons-react';

import {
    Col,
    Label,
} from "reactstrap"
import { Field } from 'formik';
import { GalliumInput } from 'Components/Gallium/GalliumForms';

const ServiceGatewaySettings = ({ hypervisors }) => {

    return (
        <React.Fragment>
            <div className="d-flex mb-3">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon="settings" className={"text-info icon-dual-info icon-xs"} />
                </div>
                <div className="flex-grow-1">
                    <h5>Settings</h5>
                    <Col md={4} className="mb-3">
                        <Field
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Enter a friendly name"
                            type="text"
                            label="Name"
                            component={GalliumInput}
                        />
                    </Col>

                    <div className="mt-0 mb-3">
                        <Label className="form-label" htmlFor="hypervisorField">Traffic Ingress Hypervisor</Label>

                        <Col md={6}>
                            <Field
                                id="hypervisorField"
                                name="hypervisor"
                                as="select"
                                className="form-select mb-3"
                            >
                                <option value="">Please Select Hypervisor</option>
                                {hypervisors ? (hypervisors?.map((item) =>(
                                    <option value={item.slug} key={item.slug}>{item.name}</option>
                                ))):(null)}
                            </Field>
                        </Col>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ServiceGatewaySettings;
