// @ts-nocheck
import { VmDiskType } from 'generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'

import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';
import AddDisk from '../Edit/AddDisk';
import DiskDetachButton from '../Edit/DetachDisk';
import DiskExpandButton from '../Edit/ExpandDisk';

const VirtualMachineDisk  = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const diskAllocatedPercent = (disk.sizeUsedMb / disk.sizeTotalMb) * 100

    const diskAvailablePercent = ((disk.sizeTotalMb - disk.sizeUsedMb) / disk.totalMb) * 100

    const diskAllocatedString = formatDiskSize(disk.sizeUsedMb);
    const diskAvailableString = formatDiskSize(disk.sizeTotalMb - disk.sizeUsedMb);

    return (

        <Card className="product" key={disk.slug}>
            <CardBody className='pb-0'>
                <Row className="gy-3">
                    <div className="col-sm">
                        <h5 className="fs-14 text-truncate text-dark">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["hard-drive"]} className='me-2'/>
                            {disk.boot ? t('translation:virtualMachine:diskTitleBoot') :  t('translation:virtualMachine:diskTitle')}
                        </h5>
                        <ul className="list-inline text-muted mb-0">
                            <li className="list-inline-item">
                                {t('translation:virtualMachine:diskPath') + ": "}
                                <span className="fw-medium">
                                    {disk.devicePath}
                                </span>
                            </li>
                        </ul>

                          
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex align-items-center gap-2 text-muted">
                            <DiskExpandButton disk={disk} virtualMachine={virtualMachine} />
                            {disk.boot ? (
                                null
                            ):(
                                <DiskDetachButton disk={disk} virtualMachine={virtualMachine} />
                            )}
                        </div>
                    </div>
                </Row>
            </CardBody>
            <CardBody className='pt-0'>
                <div className="mt-3 pt-0">
                    <div className="progress progress-lg rounded-pill">
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${diskAllocatedPercent}%` }} aria-valuenow={diskAllocatedPercent} aria-valuemin="0" aria-valuemax="100"></div>
                        <div className="progress-bar bg-secondary-subtle" role="progressbar" style={{ width: `${diskAvailablePercent}%` }} aria-valuenow={diskAvailablePercent} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

                <div className="mt-2 pt-2">
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-primary me-2"></i>
                                {t('translation:virtualMachine:diskSpaceUsed')}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{diskAllocatedString}</p>
                        </div>
                    </div>
                    <div className="d-flex mb-0">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-secondary me-2"></i>
                                {t('translation:virtualMachine:diskSpaceFree')}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{diskAvailableString}</p>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )

}

const VirtualMachineOpticalDisk  = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    return (

        <Card className="product" key={disk.slug}>
            <CardBody className='pb-0'>
                <Row className="gy-3">
                    <div className="col-sm">
                        <h5 className="fs-14 text-truncate text-dark">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["compact-disc"]} className='me-2'/>
                            {t('translation:virtualMachine:opticalDiskTitle')}
                        </h5>
                        <ul className="list-inline text-muted mb-0">
                            <li className="list-inline-item">
                                {t('translation:virtualMachine:diskPath') + ": "}
                                <span className="fw-medium">
                                    {disk.devicePath}
                                </span>
                            </li>
                            {disk.isoFilename ? (
                                <li className="list-inline-item">
                                    {t('translation:virtualMachine:opticalDiskFile') + ": "}
                                    <span className="fw-medium">
                                        {disk.isoFilename}
                                    </span>
                                </li>
                            ):(null)}
                        </ul>

                          
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex align-items-center gap-2 text-muted">
                            <DiskDetachButton disk={disk} virtualMachine={virtualMachine} />
                        </div>
                    </div>
                </Row>
            </CardBody>
            <CardBody className='pt-0'>
                <div className="mt-2 pt-2">
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-primary me-2"></i>
                                {t('translation:virtualMachine:diskSpaceSize')}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{formatDiskSize(disk.sizeTotalMb)}</p>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )

}


const VirtualMachineStorage = ({ disks, virtualMachine }) => {

    return (
        <React.Fragment>
            <AddDisk virtualMachine={virtualMachine} />
            {disks.map((disk, index) => 
                disk.deviceType === VmDiskType.CDROM ? (
                    <VirtualMachineOpticalDisk disk={disk} virtualMachine={virtualMachine} key={index}/>
                ) : (
                    <VirtualMachineDisk disk={disk} key={index} virtualMachine={virtualMachine} />
                )
            )}
        </React.Fragment>
    )
};

export default VirtualMachineStorage;