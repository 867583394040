import { HostState, MonitoringIncidentStatus, TemplateState } from 'generated';
import React from 'react';
import StringTidy from './StringTidy';


interface StatusBadgeProps {
  status: string | boolean;
  className?: string
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, className }) => {

    let color: string, text: string

    switch (status) {
    case "running":
        color = "success";
        text = "Running";
        break;
    case "stopped":
        color = "secondary";
        text = "Stopped";
        break;
    case "submitted":
        color = "primary";
        text = "Provisioning";
        break;
    case "hostOffline":
        color = "secondary";
        text = "Host Offline";
        break;
    case "COMPLETE":
        color = "success";
        text = "Completed";
        break;
    case "IN_PROGRESS":
        color = "primary";
        text = "In Progress";
        break;      
    case "PENDING":
        color = "info";
        text = "Pending";
        break;
    case "FAILED":
        color = "danger";
        text = "Failed";
        break;
    case MonitoringIncidentStatus.ACKNOWLEDGED:
        color = "warning";
        text = StringTidy(MonitoringIncidentStatus.ACKNOWLEDGED) ;
        break;
    case MonitoringIncidentStatus.CLOSED:
        color = "secondary";
        text = StringTidy(MonitoringIncidentStatus.CLOSED) ;
        break;
    case MonitoringIncidentStatus.OPEN:
        color = "danger";
        text = StringTidy(MonitoringIncidentStatus.OPEN) ;
        break;
    case HostState.ONLINE:
        color = "success";
        text = StringTidy(HostState.ONLINE) ;
        break;
    case HostState.JOIN_PENDING:
        color = "warning";
        text = StringTidy(HostState.JOIN_PENDING) ;
        break;
    case HostState.OFFLINE:
        color = "danger";
        text = StringTidy(HostState.OFFLINE) ;
        break;
    case TemplateState.DRAFT:
        color = "primary";
        text = StringTidy(TemplateState.DRAFT) ;
        break;
    case TemplateState.PROCESSING:
        color = "warning";
        text = StringTidy(TemplateState.PROCESSING) ;
        break;
    case TemplateState.READY:
        color = "success";
        text = StringTidy(TemplateState.READY) ;
        break;
    case "200":
        color = "success";
        text = status;
        break;
    case true:
        color = "success";
        text = "True";
        break;
    case false:
        color = "danger";
        text = "False";
        break;                     
    default:
        color = "secondary";
        text = status;
    }

    return (
        <React.Fragment>
            <span className={`${className} badge bg-${color}-subtle text-${color}`}>{text}</span>
        </React.Fragment>
    )
};

export default StatusBadge;