// @ts-nocheck
// TODO Refactor in SWR
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BreadCrumb from '../../../Components/Common/Breadcrumb';

import Loader from "../../../Components/Gallium/Loader";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Button,
    Tooltip
} from "reactstrap";

import * as Yup from "yup";

import classnames from "classnames";

import { useFormik, FormikProvider, Field } from "formik";

import NetworkInterfaces from './NetworkInterfaces';
import Disks from './Disks';
import CPUMemory from './CPUMemory';
import Basics from './Basics';
import Authentication from './Authentication';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import Cloudinit from './Cloudinit';
import { useGetAllHosts, useGetHost, useNewVm } from 'GalliumAPIHooks/Host/HostHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useListTemplates } from 'GalliumAPIHooks/Templates/TemplateHooks';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { CreateVmRequest, GalliumApiErrorResponse, GalliumApiSuccessResponse } from 'generated';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';


const CreateVM = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {data:hypervisorsList, isLoading: isHypervisorListLoading, error: hypervisorListError} = useGetAllHosts()
    const {data:templatesList, isLoading: isTemplatesLoading, error: templateError} = useListTemplates()
    const navigate = useNavigate()
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            const modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    const [toTemplateBtntooltipOpen, setToTemplateBtnTooltipOpen] = React.useState(false);

    const { trigger, isMutating } = useNewVm(searchParams.get("hypervisor"));

    const [errorObject, setErrorObject] = useState(null);

    const handleCreateVirtualMachineFail = (error: GalliumApiErrorResponse) => {
        // toast.info(t('translation:virtualMachine:diskExpandAPIFail'))
        setErrorObject(error || {})
    }

    const handleCreateVirtualMachineSuccess = (response: GalliumApiSuccessResponse) => {
        // toast.info(t('translation:virtualMachine:diskExpandAPISuccess'))
        navigate(`/vm/${response.command.vmSlug}`)
    }

    const handleCreateVirtualMachineRequest = (requestObj) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleCreateVirtualMachineFail(error)
            },
            onSuccess(response: GalliumApiSuccessResponse) {
                handleCreateVirtualMachineSuccess(response)
            }
        }
        setErrorObject(null)
        
        trigger(requestObj, options);
    }
    // Formik
    const addVirtualMachineFormik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // MaxSize: hypervisor?.storagePools[0].freeMb,

        initialValues: {
            name: '',
            hypervisorSlug: searchParams.get("hypervisor") || 'not-set',
            templateSlug: 'debian11',
            memory: 4096,
            cpus: "1",
            description: "",
            sshAutoAdd: true,
            storagePool: '',
            networkInterfaces: ["not-set"],
            disks: [],
            cloudInitUserData: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            autoAdd: Yup.boolean(),
            hypervisorSlug: Yup.string().required("Please Select A Hypervisor"),
            networkInterfaces: Yup.array()
                .of(Yup.string().required("Adaptor must be bound to an interface"))
                .test(
                    'unique-network-interfaces', 
                    'Only one network adaptor per bound interface is allowed', 
                    (networkInterfaces) => {
                        if (!networkInterfaces) return true; // If undefined or null, skip this test
                        const unique = new Set(networkInterfaces);
                        return unique.size === networkInterfaces.length;
                    }
                ),
        }),
        onSubmit: (values) => {
            const diskArray = values["disks"]
                .filter(obj => obj.diskType === "DISK")
                .map(obj => ({
                    sizeMb: obj.size,
                    dev: obj.targetDev
                }));

            const newVM = {
                name: values["name"],
                description: values["description"],
                cpus: values["cpus"],
                memory: values["memory"],
                templateSlug: values["templateSlug"],
                rootPasswordPlaintext: values["rootPassword"],
                networkInterfaces: values["networkInterfaces"].map((str) => ({ slug: str })),
                sshAutoAdd: values["sshAutoAdd"],
                disks: diskArray,
                cloudInitUserData: values["cloudInitUserData"]
            };

            const targetHypervisor = values["hypervisorSlug"]
            // @ts-ignore
            window.Intercom('trackEvent', 'create-vm-request');
            // save new key

            handleCreateVirtualMachineRequest({hostSlug: values["hypervisorSlug"], vm: newVM})
            // dispatch(addVirtualMachine(newVM, targetHypervisor, props.router.navigate));
        },
    });

    const {data: hypervisor, isloading: isHypervisorLoading, error: isHypervisorError} = useGetHost(addVirtualMachineFormik.values["hypervisorSlug"])
    // Load the default network adaptor into the Formik
    useEffect(() => {
        if (hypervisor && !isHypervisorLoading && !isHypervisorError) { 
            const networkSlug = hypervisor.defaultNetworkSlug || hypervisor.availableNetworks?.[0]?.slug;
            if (networkSlug) {
                addVirtualMachineFormik.setFieldValue('networkInterfaces[0]', networkSlug);
            }        
        }
    }, [hypervisor, isHypervisorLoading, isHypervisorError]);


    // Deals with loading the disk settings into the Formik
    const templateSlug = addVirtualMachineFormik.values.templateSlug; 
    const [template, setTemplate] = useState(null)
    useEffect(() => {
        if (templateSlug && templatesList?.templates.length > 0) { 
            const template = templatesList.templates.find(t => t.slug === templateSlug);
            if (template) {
                setTemplate(template)
                const diskout = template.disks.map(disk => ({
                    ...disk, 
                    size: disk.minSizeMb || 1,
                }));
                addVirtualMachineFormik.setFieldValue('disks', diskout);
            }
        }
    }, [templateSlug, templatesList]);

    const crumbs = [
        {
            name: "Virtual Machines",
            link: "/vm"
        },
        {
            name: "Create Virtual Machine",
        }
    ]
    document.title = "Create Virtual Machine | Gallium";
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title="Create Virtual Machine" crumbs={crumbs} />
                    <Row>
                        <Col>
                            <ErrorAlert errorObj={errorObject} />
                            <FormikProvider className="tablelist-form" value={addVirtualMachineFormik}>
                                <Card>
                                    <CardBody className="checkout-tab">
                                        <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                            <Nav
                                                className="nav-pills nav-justified custom-nav"
                                                role="tablist"
                                            >
                                                <NavItem role="presentation">
                                                    <NavLink href="#"
                                                        className={classnames({ active: activeTab === 1, done: (activeTab <= 3 && activeTab >= 0) }, "p-3 fs-15")}
                                                    
                                                    >
                                                        <i className="ri-server-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                    Hypervisor
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem role="presentation">
                                                    <NavLink href="#"
                                                        className={classnames({ active: activeTab === 2, done: activeTab <= 3 && activeTab > 1 }, "p-3 fs-15")}
                                                    
                                                    >
                                                        <i className="ri-file-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                    Template
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem role="presentation">
                                                    <NavLink href="#"
                                                        className={classnames({ active: activeTab === 3, done: activeTab <= 3 && activeTab > 2 }, "p-3 fs-15")}
                                                    
                                                    >
                                                        <i className="ri-bank-card-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                    Settings
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={1} id="pills-bill-info">
                                                {isHypervisorListLoading ? (<Loader />) : hypervisorListError ? (<ErrorNotice />) : (
                                                    <React.Fragment>
                                                        <div>
                                                            <h5 className="mb-1">Hypervisor Selection</h5>
                                                            <p className="text-muted mb-4">
                                                            Which Hypervisor would you like to provision this VM on?
                                                            </p>
                                                        </div>

                                                        <div>


                                                            <Row className="gy-3">
                                                                {hypervisorsList.filter(item => item.availableActions.createVm).map((item, key) => (
                                                                    <Col lg={3} key={key}>
                                                                        <div className="form-check card-radio">
                                                                            <Field
                                                                                id={key}
                                                                                name="hypervisorSlug"
                                                                                value={item.slug}
                                                                                type="radio"
                                                                                className="form-check-input"
                                                                                onChange={addVirtualMachineFormik.handleChange}
                                                                                onBlur={addVirtualMachineFormik.handleBlur}
                                                                            />
                                                                            <Label
                                                                                className="form-check-label"
                                                                                htmlFor={key}
                                                                            >
                                                                                <span className="fs-14 mb-1 text-wrap d-block">
                                                                                Hypervisor
                                                                                </span>
                                                                                <span className="text-muted fw-normal text-wrap d-block">
                                                                                    {item.name}
                                                                                </span>
                                                                            </Label>
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                        
                                                            </Row>
                                                            <div className="d-flex align-items-start gap-3 mt-3">
                                                                {addVirtualMachineFormik.values.hypervisorSlug !== '' && addVirtualMachineFormik.errors.hypervisorSlug === undefined ? (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-label right ms-auto nexttab"
                                                                        onClick={() => {
                                                                            toggleTab(activeTab + 1);
                                                                        }}
                                                                    >
                                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                    Proceed to Template
                                                                    </button>
                                                                ) : 
                                                                    <React.Fragment>
                                                                        <Button
                                                                            type="button"
                                                                            id="ToTemplateBtn"
                                                                            className="btn btn-secondary btn-label right ms-auto nexttab"
                                                                        >
                                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                        Proceed to Template
                                                                        </Button>

                                                                        <Tooltip
                                                                            isOpen={toTemplateBtntooltipOpen}
                                                                            placement="right"
                                                                            target="ToTemplateBtn"
                                                                            toggle={() => { setToTemplateBtnTooltipOpen(!toTemplateBtntooltipOpen) }}>
                                                                            {addVirtualMachineFormik.errors.hypervisorSlug}
                                                                        </Tooltip>
                                                                    </React.Fragment>
                                                                }
                                                        
                                                            
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </TabPane>

                                            <TabPane tabId={2}>
                                                {isTemplatesLoading ? (<Loader />) : templateError ? (<ErrorNotice />) : (
                                                    <React.Fragment>
                                                        <div>
                                                            <h5 className="mb-1">Template Selection</h5>
                                                            <p className="text-muted mb-4">
                                                            Which Template would you like to use for this VM?
                                                            </p>
                                                        </div>

                                                        <div className="mt-4">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="flex-grow-1">
                                                                    <h5 className="fs-14 mb-0">Available Templates</h5>
                                                                </div>
                                                                <div className="flex-shrink-0">

                                                                </div>
                                                            </div>
                                                            <Row className="gy-3">
                                                                {templatesList?.templates?.length  > 0 ? (
                                                                    templatesList?.templates.map((item, key) => (
                                                                        <Col lg={4} sm={6} key={key}>
                                                                            <div className="form-check card-radio">
                                                                                <Field
                                                                                    id={"template"+key}
                                                                                    name="templateSlug"
                                                                                    value={item.slug}
                                                                                    type="radio"
                                                                                    className="form-check-input"
                                                                                    onChange={addVirtualMachineFormik.handleChange}
                                                                                    onBlur={addVirtualMachineFormik.handleBlur}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label"
                                                                                    htmlFor={"template"+key}
                                                                                >
                                                                                    <span className="mb-4 fw-semibold d-block text-muted text-uppercase">
                                                                                        {item.osType}
                                                                                    </span>

                                                                                    <span className="fs-14 mb-2 d-block">
                                                                                        {item.name}
                                                                                    </span>
                                                                                </Label>
                                                                            </div>
                                                                        </Col>
                                                                    ))
                                                                ) : (<Loader />)}
                                                            
                                                            </Row>
                                                        </div>
                                                        <div className="d-flex align-items-start gap-3 mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-light btn-label previestab"
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1);
                                                                }}
                                                            >
                                                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                                Back to Hypervisor Selection
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-label right ms-auto nexttab"
                                                                onClick={() => {
                                                                    toggleTab(activeTab + 1);
                                                                }}
                                                            >
                                                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                Continue to Settings
                                                            </button>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </TabPane>

                                            <TabPane tabId={3}>
                                                {isHypervisorLoading ? (<Loader />) : isHypervisorError ? (<ErrorNotice />) : (
                                                    <React.Fragment> 
                                                        <Basics hypervisor={hypervisor} />
                                                        <CPUMemory hypervisor={hypervisor} />
                                                        <Disks hypervisor={hypervisor}/>
                                                        <NetworkInterfaces hypervisor={hypervisor} />
                                                        <Authentication template={template}/>
                                                        <Cloudinit template={template}/>
                                                    </React.Fragment>
                                                )}
                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                    Back to Template
                                                    </button>
                                                    <GalliumSubmitButton formik={addVirtualMachineFormik} color="success" className="ms-auto right nexttab" spinner={isMutating}>
                                                        Create VM
                                                    </GalliumSubmitButton>
                                                </div>
                                                
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </FormikProvider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateVM;