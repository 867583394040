
import React, { } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { round } from "lodash"

import { DiskDetail } from 'generated';

interface DiskModalProps {
    showModal: boolean,
    closeModal: () => void,
    disk: DiskDetail
}

const DiskModal: React.FC<DiskModalProps> = ({ showModal, closeModal, disk }) => {

    return (
        <Modal isOpen={showModal} toggle={closeModal} centered={true} size="lg">

            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                Disk Information
            </ModalHeader>  
            <ModalBody>

                <dl className="row mb-0">

                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">Info</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">Serial</dt>
                            <dd className="col-sm-8">{disk?.serialNumber || "Unknown"}</dd>
                            <dt className="col-sm-4 text-truncate">Description</dt>
                            <dd className="col-sm-8">{disk?.description || "Unknown"}</dd>
                            <dt className="col-sm-4 text-truncate">Type</dt>
                            <dd className="col-sm-8">{disk?.deviceType || "Unknown"}</dd>
                            <dt className="col-sm-4 text-truncate">Size (GB)</dt>
                            <dd className="col-sm-8">{round(disk?.sizeBytes / 1073741824,0) || "Unknown"}</dd>
                        </dl>
                    </dd>

                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">Block Size</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">Logical</dt>
                            <dd className="col-sm-8">{disk?.logicalBlockSize || "Unknown"}</dd>
                            <dt className="col-sm-4 text-truncate">Physical</dt>
                            <dd className="col-sm-8">{disk?.physicalBlockSize || "Unknown"}</dd>
                            
                        </dl>
                    </dd>

                    <dt className="col-sm-3 text-truncate mb-2 border-bottom border-bottom-dashed">Partitions</dt>
                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                        {disk?.existingPartitions?.length=== 0 ? <dd className="col-sm-4 text-truncate">None Found</dd> : null }
                        {disk?.existingPartitions?.map((partition,index) => (
                            <dl className="row mb-0 border-bottom border-bottom-dashed" key={index}>
                                <dt className="col-sm-4 text-truncate">Number</dt>
                                <dd className="col-sm-8">{partition.partNum || "Unknown"}</dd>
                                <dt className="col-sm-4 text-truncate">Type</dt>
                                <dd className="col-sm-8">{partition.type || "Unknown"}</dd>
                                <dt className="col-sm-4 text-truncate">Name</dt>
                                <dd className="col-sm-8">{partition.name || "Unknown"}</dd>
                                <dt className="col-sm-4 text-truncate">Size (MB)</dt>
                                <dd className="col-sm-8">{round(partition?.sizeBytes / 1000000,0) || "Unknown"}</dd>
                            </dl>
                        ))}
                    </dd>

                    <dt className="col-sm-3 mb-0">Validation</dt>
                    <dd className="col-sm-9 mb-0">
                        <dl className="row mb-0">
                            <dt className="col-sm-4 text-truncate">Boot</dt>
                            <dd className="col-sm-8">{disk?.boot ? "Yes" : "No"}</dd>
                            <dt className="col-sm-4 text-truncate">Pool Member</dt>
                            <dd className="col-sm-8">{disk?.data ? "Yes" : "No"}</dd>
                            <dt className="col-sm-4 text-truncate">Usable</dt>
                            <dd className="col-sm-8">{disk?.usable ? "Yes" : "No"}</dd>
                            <dt className="col-sm-4 text-truncate">Warnings</dt>
                            <dd className="col-sm-8">{disk?.unusableReason || "None"}</dd>
                        </dl>
                    </dd>

                </dl>

            </ModalBody>
        </Modal>
    );
};

export default DiskModal;