import { combineReducers } from "redux"

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"

import Templates from "./templates/reducer"

import uiReducer from "./uiActions/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    Templates,
    uiReducer
})

export default rootReducer
