import { ConfirmMfaRequest, CreateMfaRequestBase, CreateMfaResponseBase, CurrentUser, GalliumApiErrorResponse, GalliumApiSuccessResponse, UpdateUserRequest, UserService } from "generated";

import useSWR from "swr";
import useSWRMutation from 'swr/mutation'


export const useUserProfileRequest = () => {
    const getCurrentUser = () => UserService.getCurrentUser()

    const { data, error, isLoading } = useSWR<CurrentUser>(`user`, getCurrentUser);

    return { data, error, isLoading };
};

export const useUserProfileUpdateRequest = () => {
    const updateUser = (key: null, {arg}:{arg:UpdateUserRequest}) => UserService.updateUser(arg)

    const { trigger, error, isMutating } = useSWRMutation<CurrentUser, GalliumApiErrorResponse, string, UpdateUserRequest>(`user`, updateUser); 

    return { trigger, error, isMutating };
};

export const useUserProfileMFACreateRequest = () => {
    const createMfaMethod = (key: string, {arg}:{arg:CreateMfaRequestBase}) => UserService.createMfaMethod(arg); 

    const { data, trigger, error, isMutating } = useSWRMutation<CreateMfaResponseBase, GalliumApiErrorResponse, string, CreateMfaRequestBase>(`user`, createMfaMethod);

    return { data, trigger, error, isMutating };
};

export const useUserProfileMFAConfirmRequest = () => {
    const confirmMfaMethod = (key: string, {arg}:{arg:ConfirmMfaRequest}) => {
        const otpObj = {otp: arg.otp}
        // @ts-ignore
        // TODO Fix this to be more type safe
        return UserService.confirmMfaMethod(arg.mfaSlug, otpObj)
    }

    const { data, trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string, ConfirmMfaRequest>(`api/user`, confirmMfaMethod);

    return { data, trigger, error, isMutating };
};

export const useUserProfileMFADeleteRequest = (mfaSlug: string) => {
    const deleteMfaMethod = (key: string) => UserService.deleteMfaMethod(mfaSlug); 

    const { data, trigger, error, isMutating } = useSWRMutation<GalliumApiSuccessResponse, GalliumApiErrorResponse, string>(`user`, deleteMfaMethod);

    return { data, trigger, error, isMutating };
};