// @ts-nocheck
import React, { useState, useContext } from "react";
import { ModalContext } from "./MFAModal";

import { Label, ModalBody, ModalHeader } from "reactstrap";

import { toast } from "react-toastify";

import { Col, Row } from 'reactstrap';

import { useUserProfileMFAConfirmRequest } from "GalliumAPIHooks/User/UserProfileHooks";

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field, Form } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";

const TOTPConfirm = () => {
    const { response, setResponse, closeModal } = useContext(ModalContext);

    const { qrData, slug: mfaSlug } = response

    const { trigger, isMutating } = useUserProfileMFAConfirmRequest();

    const [errorObject, setErrorObject] = useState(undefined);

    const handleTOTPConfirmFail = (error) => {
        confirmTOTPFormik.setErrors(error.response.data.errors || {})
        setErrorObject(error.response.data || {})
    }

    const handleTOTPConfirmSuccess = () => {
        confirmTOTPFormik.resetForm()
        toast.success("MFA Method Added", { autoClose: 3000 });
        closeModal()
    }

    const handleTOTPConfirmRequest = (values: object) => {
        const options = {
            onError(error: object) {
                handleTOTPConfirmFail(error)
            },
            onSuccess() {
                handleTOTPConfirmSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const confirmTOTPFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            otp: '',
            mfaSlug: mfaSlug
        },
        validationSchema: Yup.object().shape({
            otp: Yup.string()
                .required('Code is required')
        }),
        onSubmit: (values) => {
            handleTOTPConfirmRequest(values);
        }
    });

    return (
        <React.Fragment>
            <ModalHeader className="p-3 bg-light">
            Setup and Confirm TOTP
            </ModalHeader>      
            <ModalBody className="py-3 px-5 mb-3">
            
                <FormikProvider value={confirmTOTPFormik}>
                    <ErrorAlert errorObj={errorObject}/>
                    <Row className="g-2">
                        <Label className="mb-0">Enrollment QR Code</Label>
                        <p className="text-muted m-0">Use the following QR code to set up TOTP in your preferred application. Once setup please enter a code below to confirm this method.</p>
                        <div className="text-center">
                            <img src={qrData} className="img-fluid m-0" alt="QR Code for TOTP Enrollment" />
                        </div>
                    </Row>
                    <Row className="g-2">
                        <Col lg={12}>
                            <Field
                                type="string"
                                id="otp"
                                name="otp"
                                label="Code"
                                component={GalliumInput}
                            />
                        </Col>
                        <Col lg={12} className="text-end mt-3">
                            <GalliumSubmitButton formik={confirmTOTPFormik} color="success" spinner={isMutating}>
                            Complete Setup
                            </GalliumSubmitButton>
                        </Col>
                    </Row>
                </FormikProvider>
            </ModalBody>
        </React.Fragment>
    );
};

export default TOTPConfirm