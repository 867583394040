import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";

import React, { useEffect, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, GalliumApiSuccessResponse, HostDetail, HostPowerAction, HostPowerStateRequest, VmPowerAction } from 'generated';
import { useHostPowerStateChange } from 'GalliumAPIHooks/Host/HostHooks';
import { GalliumInput } from 'Components/Gallium/GalliumForms';
import ErrorAlert from "Components/Gallium/ErrorHelper";
import StringTidy from "Components/Gallium/StringTidy";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface PowerModalProps {
    showModal: boolean,
    closeModal: () => void,
    hypervisor: HostDetail
    hostPowerAction: HostPowerAction
}

const PowerModal: React.FC<PowerModalProps> = ({ showModal, closeModal, hypervisor, hostPowerAction }) => {
    const { t } = useTranslation()

    const [errorObject, setErrorObject] = useState(undefined)

    const { 
        trigger: triggerHostPowerStateChange, 
        isMutating,
    } = useHostPowerStateChange(hypervisor.slug)


    const handleHostPowerStateChangeFail = (error: GalliumApiErrorResponse) => {
        hostPowerFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleHostPowerStateChangeSuccess = () => {
        toast.success("Power State Change Requested")
        closeModal()
    }

    const handleHostPowerStateChangeRequest = (values: HostPowerStateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleHostPowerStateChangeFail(error)
            },
            onSuccess() {
                handleHostPowerStateChangeSuccess()
            }
        }
        setErrorObject(undefined)
        triggerHostPowerStateChange(values, options);
    }

    const hostPowerFormik = useFormik({
        initialValues: {
            force: false,
            timeoutSeconds: 600,
            hostPowerAction: hostPowerAction,
            vmPowerAction: VmPowerAction.PAUSE
        },
        validationSchema: Yup.object({
            force: Yup.boolean().required("Force is required"),
            timeoutSeconds: Yup.number().required("Timeout seconds is required").min(0, "Timeout must be greater than or equal to 0"),
            hostPowerAction: Yup.string().oneOf(Object.values(HostPowerAction), "Invalid host power action"),
            vmPowerAction: Yup.string().oneOf(Object.values(VmPowerAction), "Invalid VM power action").required("VM Power action is required")
        }),
        onSubmit: (values: HostPowerStateRequest) => {
            handleHostPowerStateChangeRequest(values);
        }
    });

    useEffect(() => {
        hostPowerFormik.setFieldValue('hostPowerAction', hostPowerAction)
    }, [hostPowerAction]);

    return (
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>

            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t(`hostPowerAction.${hostPowerAction}`)} Hypervisor
            </ModalHeader>  
            <ModalBody className="py-3 px-5">
                
                <ErrorAlert errorObj={errorObject} />
                <FormikProvider value={hostPowerFormik}>
                    <Row>
                        <Label className="form-label" htmlFor="vmPowerAction">Running Virtual Machine Action</Label>
                        <Col mb={3}>
                            <Field
                                id="vmPowerAction"
                                name="vmPowerAction"
                                as="select"
                                className="form-select mb-3"
                            >
                                {Object.values(VmPowerAction).map((item, index) =>(
                                    <option value={item} key={index}>{StringTidy(item)}</option>
                                ))}
                                

                            </Field>
                        </Col>

                        <Field
                            label="Virtual Machine Timeout"
                            name="timeoutSeconds"
                            id="timeoutSeconds"
                            className="mb-3"
                            placeholder="Number of seconds to wait for shutdown"
                            type="number"
                            component={GalliumInput}
                        />

                        <Col mb={3}>
                            <div className="form-check form-switch form-switch-lg form-switch-danger" dir="ltr">                       
                                <Field
                                    name="force"
                                    id="force"
                                    type="checkbox"
                                    className="form-check-input"
                                />
                                <Label className="form-check-label" htmlFor="force">Halt on Timeout</Label>  
                            </div>
                            <p className="text-muted">Use this option to force virtual machines to halt on timeout or cancel the power request.</p>
                        </Col>
                    </Row>
                </FormikProvider>
                <div className="d-flex gap-2 float-end mt-4 mb-2">
                    <GalliumSubmitButton formik={hostPowerFormik} spinner={isMutating} color="danger">
                        {t(`hostPowerAction.${hostPowerAction}`)}
                    </GalliumSubmitButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default PowerModal;