import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Label, Card, CardBody, Container, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { GalliumApiErrorResponse, SshKeyAddRequest } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { useAddSSHKey } from 'GalliumAPIHooks/SSH/SSHHooks';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import { useTranslation } from 'react-i18next';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';

const AddSSHKey = () => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useAddSSHKey()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handleAddSSHKeyFail = (error: GalliumApiErrorResponse) => {
        addKeyFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddSSHKeySuccess = () => {
        toast.success("SSH Key Added")
        navigate("/inventory/sshkeys");
    }

    const handleAddSSHKeyRequest = (values: SshKeyAddRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleAddSSHKeyFail(error)
            },
            onSuccess() {
                handleAddSSHKeySuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const addKeyFormik = useFormik({
        initialValues: {
            name: '',
            key: '',
            autoAdd: false,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            key: Yup.string().required("Please Enter Key Data"),
            autoAdd: Yup.boolean()

        }),
        onSubmit: (values) => {
            handleAddSSHKeyRequest(values)
        },
    });


    document.title = "Add SSH Key | Gallium";

    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: t("sshKey.title"),
            link: "/inventory/sshkeys"
        },
        {
            name: t("sshKey.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("sshKey.add")} crumbs={crumbs} />
                    <FormikProvider value={addKeyFormik}>
                        <Card>
                            <CardBody>
                                <ErrorAlert errorObj={errorObject} />
                                <GalliumFormHeader icon="info" title="Key Settings">
                                    <Col md={4} className="mb-3">
                                        <Field
                                            name="name"
                                            id="name"
                                            className="form-control"
                                            placeholder="Enter a friend name for this Key"
                                            type="text"
                                            label="Key Name"
                                            component={GalliumInput}
                                        />
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Field
                                            name="key"
                                            id="key"
                                            className="form-control"
                                            placeholder="Enter Base64 encoded Key"
                                            type="textarea"
                                            label="Key"
                                            component={GalliumInput}
                                        />
                                    </Col>

                                    <div className="form-check form-switch form-switch-lg" dir="ltr">
                                        <Field
                                            id="autoAdd" 
                                            name="autoAdd" 
                                            type="checkbox" 
                                            className="form-check-input"  
                                        />
                                        <Label className="form-check-label" htmlFor="autoAdd">Automatically add to new Virtual Machines</Label>
                                    </div>
                                </GalliumFormHeader>
                                <GalliumSubmitButton formik={addKeyFormik} spinner={isMutating} color="success" className="mt-2 float-end">
                                    Add
                                </GalliumSubmitButton>
                            </CardBody>
                        </Card>

                        <Col md={6} className="ps-0">
                            <Link to="/inventory/sshkeys">
                                <button className="btn btn-link text-decoration-none btn-label">
                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                    Back to SSH Keys
                                </button>
                            </Link> 
                        </Col>
                        <Col md={6} className="text-end pe-0">   
                            
                        </Col>

                    </FormikProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddSSHKey;