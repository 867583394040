import React from 'react';

import { Field } from "formik";

import FeatherIcon from 'feather-icons-react';

import {
    Label,
    Col,
    Row,

} from "reactstrap"

const OSType = ({ createTemplateFormik }) => {

    const availableOperatingSystems = [
        {"name": "Linux", "value": "LINUX", "icon": "bx bxl-tux bx-md", "description": "Supports most distros"},
        {"name": "Windows", "value": "WINDOWS", "icon": "bx bxl-windows bx-md", "description": "Version 2016 and above"},
        {"name": "Other", "value": "OTHER", "icon": "bx bx-file-blank bx-md", "description": "Maximum Compatibility"}
    ];
    
    return (
        <React.Fragment>
            <div className="d-flex mb-3">
                <div className="flex-shrink-0 me-3">
                    <FeatherIcon icon="cpu" className={"text-info icon-dual-info icon-xs"} />
                </div>
                
                <div className="flex-grow-1">
                    <h5>Operating System</h5>
                    <div className="mt-3 mb-3">
                        <Label className="form-label" htmlFor="key-data-input">Select Template OS</Label>
                        <Row className='gy3'>
                            {availableOperatingSystems.map((item, key) => (
                                <Col lg={4} key={key}>
                                    <div className="form-check card-radio">
                                        <Field
                                            id={"osType"+key}
                                            name="osType"
                                            value={item.value}
                                            type="radio"
                                            className="form-check-input"
                                            onChange={createTemplateFormik.handleChange}
                                            onBlur={createTemplateFormik.handleBlur}
                                        />
                                        <Label
                                            className="form-check-label"
                                            htmlFor={"osType"+key}
                                        >
                                            <span className="fs-20 float-end mt-1 text-wrap d-block">
                                                <i className={item.icon}></i>
                                            </span>
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                                {item.name}
                                            </span>
                                            <span className="text-muted fw-normal text-truncate d-block">
                                                {item.description}
                                            </span>
                                        </Label>
                            
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default OSType;
