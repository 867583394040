import StatusBadge from "Components/Gallium/StatusBadge";
import StringTidy from "Components/Gallium/StringTidy";
import React from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import IncidentControls from "../Common/IncidentControls";
import { TimeAgoString } from "Components/Gallium/GalliumTimeHelpers";

const IncidentStatus = (cell) => {
    return (
        <React.Fragment>
            <StatusBadge status={cell.getValue()}/>
        </React.Fragment>
    );
};

const IncidentType = (cell) => {
    return (
        <React.Fragment>
            <Link to={`/monitoring/${cell.row.original.slug}`} className="fw-medium link-primary">{StringTidy(cell.getValue())}</Link>
        </React.Fragment>
    );
};

const IncidentOpenedAt = (cell) => {
    return (
        <React.Fragment>
            {cell.getValue() ? (TimeAgoString(cell.getValue())):(null)}
        </React.Fragment>
    );
};

const IncidentHost = (cell) => {
    return (
        <React.Fragment>
            <Link to={`/hypervisors/${cell.getValue().slug}`} className="fw-medium link-primary">{cell.getValue().name}</Link>
        </React.Fragment>
    );
};

const IncidentActions = (cell) => {
    return (
        <React.Fragment>
            <IncidentControls incident={cell.row.original} className="btn-sm"/>
        </React.Fragment>
    );
};

export {IncidentStatus, IncidentType, IncidentOpenedAt, IncidentHost, IncidentActions}