// @ts-nocheck
import { TemplateDisk } from "generated";
import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser");
    if (user) return JSON.parse(user);
    return null;
};


// //is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Login Method

export const postJwtLogin = data => api.create(url.POST_JWT_LOGIN, data);
export const postJwtRegister = data => api.create(url.POST_JWT_REGISTER, data);


// get VNC URL
export const getVNCUrl = (virtualMachineSlug: string) => api.get(`${url.GET_VNC_URL}/${virtualMachineSlug}/vnc`)

// get hypervisor
export const getHypervisors = () => api.get(url.GET_HYPERVISORS)

// Hypervisors
export const getHypervisorDetail = id =>
    api.get(`${url.GET_HYPERVISOR_DETAIL_REQUEST}/${id}`)

export const getHypervisorMetrics = id =>
    api.get(`${url.GET_HYPERVISOR_METRICS}/${id}/metrics`)

export const getHypervisorHardware = id =>
    api.get(`${url.GET_HYPERVISOR_HARDWARE}/${id}/hardware`)

export const getHypervisorStorage = id =>
    api.get(`${url.GET_HYPERVISOR_STORAGE}/${id}/storage`)

export const updateHypervisorStorage = (storageConfig, hypervisor) => api.create(`${url.UPDATE_HYPERVISOR_STORAGE}/${hypervisor}/storage/provision`, storageConfig)
export const destroyHypervisorStorage = (storageConfig, hypervisor) => api.create(`${url.UPDATE_HYPERVISOR_STORAGE}/${hypervisor}/storage/destroy`, storageConfig)


export const addHypervisor = hypervisor => api.create(url.ADD_HYPERVISOR, hypervisor)

// get SSH Keys
export const getSSHKeys = () => api.get(url.GET_SSHKEYS)
export const addSSHKey = data => api.create(url.ADD_SSHKEY, data)
export const importSSHKeys = data => api.create(url.IMPORT_SSHKEYS, data)
export const deleteSSHKey = sshkey => api.delete(`${url.DELETE_SSHKEY}/${sshkey.slug}`)


// Virtual Machines Keys
export const getVirtualMachines = () => api.get(url.GET_VIRTUAL_MACHINES)
export const getVirtualMachineDetail = id => api.get(`${url.GET_VIRTUAL_MACHINE_DETAIL}/${id}`)
export const addVirtualMachine = (data, hypervisor) => api.create(`${url.ADD_VIRTUAL_MACHINE}/${hypervisor}/newvm`, data)
export const changeVirtualMachineState = (vm, payload) => api.create(`${url.CHANGE_VIRTUAL_MACHINE_STATE}/${vm.slug}/changeState`, payload, payload)


// Templates
export const getTemplates = (data) => api.get(url.GET_TEMPLATES, data)
export const getTemplateDetail = (slug) => api.get(`${url.GET_TEMPLATE_DETAIL}/${slug}`)
export const getTemplateDisk = (templateSlug, diskSlug) => api.get(`${url.GET_TEMPLATE_DISK}/${templateSlug}/disks/${diskSlug}`)
export const createTemplate = (data) => api.create(url.CREATE_TEMPLATE, data)
export const createTemplateDisk = (templateSlug, data) => api.create(`${url.CREATE_TEMPLATE_DISK}/${templateSlug}/newdisk`, data)
export const deleteTemplateDisk = (disk: TemplateDisk) => api.delete(`${url.DELETE_TEMPLATE_DISK}/${disk.templateSlug}/disks/${disk.slug}`)
export const updateTemplate = (templateSlug, data) => api.put(`${url.UPDATE_TEMPLATE}/${templateSlug}`, data)
export const deleteTemplate = (templateSlug) => api.delete(`${url.UPDATE_TEMPLATE}/${templateSlug}`, )

//Command Status
export const getCommands = () => api.get(url.GET_COMMANDS)

