// @ts-nocheck
//TODO revisit the componet
import React from 'react'
import PropTypes from 'prop-types'

import { formatBytes, formatDuration } from 'react-dropzone-uploader'

import { IPreviewProps } from 'react-dropzone-uploader'

import { Button, Progress } from 'reactstrap'

class Preview extends React.PureComponent {
    render() {
        const {
            className,
            imageClassName,
            style,
            imageStyle,
            fileWithMeta: { cancel, remove, restart },
            meta: { name = '', percent = 0, size = 0, previewUrl, status, duration, validationError },
            isUpload,
            canCancel,
            canRemove,
            canRestart,
            extra: { minSizeBytes },
        } = this.props

        let title = `${name || '?'}, ${formatBytes(size)}`
        if (duration) title = `${title}, ${formatDuration(duration)}`

        if (status === 'error_file_size' || status === 'error_validation') {
            return (
                <div className={className} style={style}>
                    <p className="text-muted">{title}</p>
                    {status === 'error_file_size' && <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
                    {status === 'error_validation' && <span>{String(validationError)}</span>}
                    {canRemove && <span className="dzu-previewButton" style={iconByFn.remove} onClick={remove} />}
                </div>
            )
        }

        if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
            title = `${title} (upload failed)`
        }
        if (status === 'aborted') title = `${title} (cancelled)`

        return (
            <div className="">
        

                {status === 'uploading' && canCancel && (
                    <Button color="secondary" className="float-end btn-ghost-secondary btn-sm" onClick={cancel}><i className="mdi mdi-cancel align-middle"></i></Button>
                )}

                {status !== 'preparing' && status !== 'getting_upload_params' && status !== 'uploading' && canRemove && (
                    <Button color="secondary" className="float-end btn-ghost-secondary btn-sm" onClick={remove}><i className="mdi mdi-file-cancel-outline align-middle"></i></Button>
                )}

                {['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) &&
            canRestart && <Button color="secondary" className="float-end btn-ghost-secondary btn-sm" onClick={restart}><i className="mdi mdi-restart align-middle"></i></Button>
                }

                {!previewUrl && <p className="text-muted">{title}</p>}

                <div className="dzu-previewStatusContainer">
                    {isUpload && (
                        <div>
                            <Progress 
                                max={100} 
                                value={status === 'done' || status === 'headers_received' ? 100 : percent} 
                                striped={status === 'uploading' ? true : false} 
                                animated={status === 'uploading' ? true : false} 
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

Preview.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    style: PropTypes.object,
    imageStyle: PropTypes.object,
    fileWithMeta: PropTypes.shape({
        file: PropTypes.any.isRequired,
        meta: PropTypes.object.isRequired,
        cancel: PropTypes.func.isRequired,
        restart: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        xhr: PropTypes.any,
    }).isRequired,
    // copy of fileWithMeta.meta, won't be mutated
    meta: PropTypes.shape({
        status: PropTypes.oneOf([
            'preparing',
            'error_file_size',
            'error_validation',
            'ready',
            'getting_upload_params',
            'error_upload_params',
            'uploading',
            'exception_upload',
            'aborted',
            'error_upload',
            'headers_received',
            'done',
        ]).isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string,
        uploadedDate: PropTypes.string.isRequired,
        percent: PropTypes.number,
        size: PropTypes.number,
        lastModifiedDate: PropTypes.string,
        previewUrl: PropTypes.string,
        duration: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        videoWidth: PropTypes.number,
        videoHeight: PropTypes.number,
        validationError: PropTypes.any,
    }).isRequired,
    isUpload: PropTypes.bool.isRequired,
    canCancel: PropTypes.bool.isRequired,
    canRemove: PropTypes.bool.isRequired,
    canRestart: PropTypes.bool.isRequired,
    files: PropTypes.arrayOf(PropTypes.any).isRequired, // eslint-disable-line react/no-unused-prop-types
    extra: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        reject: PropTypes.bool.isRequired,
        dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
        accept: PropTypes.string.isRequired,
        multiple: PropTypes.bool.isRequired,
        minSizeBytes: PropTypes.number.isRequired,
        maxSizeBytes: PropTypes.number.isRequired,
        maxFiles: PropTypes.number.isRequired,
    }).isRequired,
}

export default Preview