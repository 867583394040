import React from 'react';

import {
    Card,
    CardBody,
    Badge

} from "reactstrap"

const UploadDisk = ({ disk }) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {disk.type === "disk" ? (
                                <p className="text-muted mb-2"><i className="lab las la-hdd"></i> Pending Disk</p>
                            ):(
                                <p className="text-muted mb-2"><i className="ri-album-line align-middle"></i> Pending ISO</p>
                            )}
                        </div>
                        <div className="flex-shrink-0 text-end">
                            <Badge color="primary"> Pending </Badge>
                        </div>
                    </div>
                    <h6 className="text-muted p-4 text-center">Awaiting Upload</h6>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

const BlankDisk = ({ disk }) => {

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted mb-4"><i className="lab las la-hdd"></i> Blank Disk</p>
                            </div>
                        </div>
                    </div>
                    <dl className="row mb-0">
                        <dt className="col-sm-3">Minimum Size</dt>
                        <dd className="col-sm-9">{disk.minSizeMb} MB</dd>
                    </dl>

                </CardBody>
            </Card>
        </React.Fragment>
    )
};

const UploadedDisk = ({ disk }) => {

    return (
        <React.Fragment>
            <Card>
                <CardBody>

                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {disk.diskType === "DISK" ? (
                                <p className="text-muted mb-2"><i className="lab las la-hdd"></i> Disk</p>
                            ):(
                                <p className="text-muted mb-2"><i className="ri-album-line align-middle"></i> ISO</p>
                            )}
                        </div>
                        <div className="flex-shrink-0 text-end">
                            <Badge color="success"> Ready </Badge>
                        </div>
                    </div>

                    <dl className="row mb-0">
                        <dt className="col-sm-3">File Name</dt>
                        <dd className="col-sm-9">{disk.filename}</dd>

                        <dt className="col-sm-3">SHA 256</dt>
                        <dd className="col-sm-9"><code>{disk.sha256sum}</code> </dd>
                    </dl>
                    
                </CardBody>
            </Card>
        </React.Fragment>
    )
};


const DiskView = ({ disk }) => {
    if (disk.blank === false && disk.filename === null) {
        return <UploadDisk disk={disk} />;
    } else if (disk.blank === false && disk.filename) {
        return <UploadedDisk disk={disk} />;
    } else {
        return <BlankDisk disk={disk} />;
    }
};

export default DiskView;
