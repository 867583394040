import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

const Hypervisor = (cell) => {
    const hypervisor = cell.getValue()[0].ingresses[0]
    return (
        <React.Fragment>
            <Link to={"/hypervisors/" + hypervisor.hostSlug}>
                {hypervisor.hostName}   
            </Link>
        </React.Fragment>
    );
};

const Mappings = (cell) => {
    return (
        <React.Fragment>
            {cell.getValue().map((item, key)=>(
                <p key={key} className="mb-1">
                    <Badge color="light" className="text-body">{item.type} {item.ingresses[0].port}</Badge>
                    <i className="lab las la-arrow-right fs-11"></i>
                    <Badge color="light" className="text-body">{item.targets[0].vmName}:{item.targets[0].targetPort}</Badge>
                </p>
            ))}
        </React.Fragment>
    );
};

export {Mappings, Hypervisor}