import React, { useState } from "react";

import { Modal,  ModalBody, ModalHeader } from "reactstrap";

import { Col, Row } from 'reactstrap';

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumInput, GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { useUpdateHostNetwork } from "GalliumAPIHooks/HostNetwork/HostNetworkHooks";
import { GalliumApiSuccessResponse, GalliumApiErrorResponse, HostDetail, VmNetwork, HostNetworkUpdateRequest, VmNetworkType, HostVolume, HostVolumeUpdateRequest, HostVolumeDetailsResponse } from "generated";
import { useTranslation } from 'react-i18next';
import { useUpdateHostVolumeDetails } from "GalliumAPIHooks/HostVolume/HostVolumeHooks";

interface NetworkEditModalProps {
    showModal: boolean,
    closeModal: () => void,
    volume: HostVolume
    hostSlug: string
}

const NetworkEditModal: React.FC<NetworkEditModalProps> = ({ showModal, closeModal, hostSlug, volume }) => {
    const { trigger, isMutating } = useUpdateHostVolumeDetails(hostSlug, volume.slug);

    const { t } = useTranslation()

    const [errorObject, setErrorObject] = useState(undefined);

    const handleEditVolumeChangeFail = (error: GalliumApiErrorResponse) => {
        editVolumeFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleEditVolumeChangeSuccess = (data: HostVolumeDetailsResponse) => {
        editVolumeFormik.resetForm()
        closeModal()
    }

    const handleEditVolumeRequest = (values: HostVolumeUpdateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleEditVolumeChangeFail(error)
            },
            onSuccess(data: HostVolumeDetailsResponse) {
                handleEditVolumeChangeSuccess(data)
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const editVolumeFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: volume.name
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required('Name is required'),
        }),
        onSubmit: (values) => {
            handleEditVolumeRequest(values);
        }
    });

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t('volumes.editVolume')}
            </ModalHeader>      
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={editVolumeFormik}>
                    <Row className="g-2">
                        <Col lg={12}>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                label={t('common.name')}
                                component={GalliumInput}
                            />
                        </Col>
                        <Col lg={12} className="text-end mt-3">
                            <GalliumSubmitButton formik={editVolumeFormik} color="success" spinner={isMutating}>
                                {t('translation:common:update')}
                            </GalliumSubmitButton>
                        </Col>
                    </Row>
                </FormikProvider>
            </ModalBody>
        </Modal>
    );
};

export default NetworkEditModal




    


                