import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useGetOrgInfo } from "GalliumAPIHooks/Org/OrgHooks";

const Navdata = () => {
    const {t} = useTranslation()
    const history = useNavigate();
    const {data} = useGetOrgInfo()
    //state data
    const [isVirtualMachines, setIsVirtualMachines] = useState(false);
    const [isServiceProvider, setIsServiceProvider] = useState(false);

    const [isHypervisors, setIsHypervisors] = useState(false);
    const [isServiceGateway, setIsServiceGateway] = useState(false);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [isInventory, setIsInventory] = useState(false);
    const [isSettings, setIsSettings] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Hypervisors');

    const inChildOrg = JSON.parse(localStorage.getItem("authUser")).org.parentOrg ? true : false

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            const activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                const id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Hypervisors') {
            setIsHypervisors(false);
        }
    }, [
        history,
        iscurrentState,
        isHypervisors
    ]);


    const menuItems = [

        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "virtual-machines",
            label: "Virtual Machines",
            icon: <FeatherIcon icon="box" className="icon-dual" />,
            link: "/vm",
            visible: true,
            stateVariables: isVirtualMachines,
            click: function (e) {
                setIsVirtualMachines(!isVirtualMachines);
                setIscurrentState('VirtualMachines');
                updateIconSidebar(e);
            }
        },
        {
            id: "dashboard",
            label: "Hypervisors",
            icon: <FeatherIcon icon="server" className="icon-dual" />,
            visible: true,
            link: "/hypervisors",
            stateVariables: isHypervisors,
            click: function (e) {
                setIsHypervisors(!isHypervisors);
                setIscurrentState('Hypervisors');
                updateIconSidebar(e);
            }
        },
        {
            id: "monitoring",
            label: "Monitoring",
            icon: <FeatherIcon icon="alert-circle" className="icon-dual" />,
            visible: true,
            link: "/monitoring",
            stateVariables: isMonitoring,
            click: function (e) {
                setIsMonitoring(!isMonitoring);
                setIscurrentState('Monitoring');
                updateIconSidebar(e);
            }
        },
        {
            id: "servicegateway",
            label: "Service Gateways",
            icon: <FeatherIcon icon="target" className="icon-dual" />,
            visible: true,
            link: "/servicegateway",
            stateVariables: isServiceGateway,
            click: function (e) {
                setIsServiceGateway(!isServiceGateway);
                setIscurrentState('ServiceGateway');
                updateIconSidebar(e);
            }
        },
        {
            id: "inventory",
            label: "Inventory",
            icon: <FeatherIcon icon="box" className="icon-dual" />,
            visible: true,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsInventory(!isInventory);
                setIscurrentState('Inventory');
                updateIconSidebar(e);
            },
            stateVariables: isInventory,
            subItems: [
                { id: "templates", visible: inChildOrg ? false:true, label: "Templates", link: "/inventory/templates", parentId: "inventory" },
                { id: "sshkeys", visible: true, label: "SSH Keys", link: "/inventory/sshkeys", parentId: "inventory" },
            ],
        },
        {
            id: "settings",
            label: "Settings",
            icon: <FeatherIcon icon="settings" className="icon-dual" />,
            visible: true,
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsSettings(!isSettings);
                setIscurrentState('Settings');
                updateIconSidebar(e);
            },
            stateVariables: isSettings,
            subItems: [
                { id: "users", visible: true, label: "Users", link: "/settings/users", parentId: "settings" },
                { id: "alertprofiles", visible: true, label: "Alerts", link: "/settings/alerts", parentId: "settings" },
                { id: "orgSupportAccess", visible: true, label: t("supportAccess.title"), link: "/settings/support", parentId: "settings" }
            ],
        },
        {
            id: "service-provider",
            label: t("serviceProvider.title"),
            icon: <FeatherIcon icon="compass" className="icon-dual" />,
            link: "/serviceprovider/org",
            visible: data?.availableActions?.createChildOrg ? true:false,
            stateVariables: isServiceProvider,
            click: function (e) {
                setIsServiceProvider(!isServiceProvider);
                setIscurrentState('ServiceProvider');
                updateIconSidebar(e);
            }
        },
    ];
    return menuItems
};
export default Navdata;