import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

import logoLight from "assets/images/logo-light.png";

import ErrorAlert from 'Components/Gallium/ErrorHelper';

import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';

import HCaptcha from '@hcaptcha/react-hcaptcha';


//formik
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { usePasswordResetRequest } from '../../GalliumAPIHooks/User/UserHooks';

const ResetPassword = () => {
    document.title="Reset Password | Gallium";

    const {trigger, isMutating} = usePasswordResetRequest()

    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handlePasswordResetFail = (error) => {
        passwordResetFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handlePasswordResetSuccess = () => {
        navigate("/login")
    }

    const handlePasswordResetRequest = (values: object) => {
        const options = {
            onError(error: object) {
                handlePasswordResetFail(error)
            },
            onSuccess() {
                handlePasswordResetSuccess()
            },
            revalidate: false
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const passwordResetFormik = useFormik({
        enableReinitialize: true,
        
        initialValues: {
            email: "",
            captchaResponse: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            captchaResponse: Yup.string().required("No Captcha Response")
        }),
        onSubmit: (values) => {
            handlePasswordResetRequest(values)
        }
    });

    return (
        <ParticlesAuth>
            <div className="auth-page-content">                
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="45" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Forgot Password?</h5>
                                        <p className="text-muted">Request a password reset email</p>
                                    </div>

                                    <ErrorAlert errorObj={errorObject} />

                                    <div className="p-2 mt-4">
                                        <FormikProvider value={passwordResetFormik}>
                                        
                                            <Field
                                                label="Email"
                                                name="email"
                                                id="email"
                                                type="email"
                                                className="mb-3"
                                                placeholder="Enter Email"
                                                component={GalliumInput}
                                            />

                                            <div className="mt-4 text-center">
                                                <HCaptcha
                                                    sitekey="44605ea1-7e9e-4d6f-a022-dffdead568f5"
                                                    onVerify={(token) => passwordResetFormik.setFieldValue("captchaResponse", token)}
                                                />
                                            </div>


                                            <div className="mt-3 text-end">
                                                <GalliumSubmitButton formik={passwordResetFormik} color="success" spinner={isMutating}>
                                                    Send Reset Link
                                                </GalliumSubmitButton>
                                            </div>

                                        </FormikProvider>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-4 text-center">
                                <Link to="/login" className="text-primary"> Return To Login </Link>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default ResetPassword;