import { useChangeVmPowerState } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { ApiVirtualMachine, ApiVmStatus, GalliumApiSuccessResponse, VirtualMachineDetail, VmPowerStateTransition } from 'generated';
import React, { useEffect, useState } from 'react';

//redux
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

import {GalliumApiErrorResponse, CommandDetail} from 'generated'
import { GalliumSpinnerButton } from '../GalliumForms';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { useGetCommandDetails } from 'GalliumAPIHooks/Commands/CommandsHooks';


interface VMListControlsProps {
    vm: ApiVirtualMachine | VirtualMachineDetail;
}
const VMListControls: React.FC<VMListControlsProps> = ({vm}) => {

    const {trigger} = useChangeVmPowerState(vm.slug);

    const [isUpdating, setIsUpdating] = useState(false)

    const [commandUuid, setCommandUuid] = useState<string | null>(null);
    
    const { data: command } = useGetCommandDetails(commandUuid, 1000);

    const handleVirtualMachinePowerStateFail = (error: GalliumApiErrorResponse) => {
        toast.error(error?.error || "Power State Transition Failed")
        setIsUpdating(false)
    }

    const handleVirtualMachinePowerStateSuccess = (response: GalliumApiSuccessResponse) => {
        setCommandUuid(response?.command.commandUuid);
    }

    const handleVirtualMachinePowerStateRequest = (values: VmPowerStateTransition) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachinePowerStateFail(error)
            },
            onSuccess(response: GalliumApiSuccessResponse) {
                handleVirtualMachinePowerStateSuccess(response)
            }
        }
        setIsUpdating(true)
        trigger(values, options);
    }

    useEffect(() => {
        if (command?.summary.status === "COMPLETE") {
            mutate('vm')
            mutate(`hosts/${command?.summary.hostSlug}`)
            mutate(`vm/${command?.summary.vmSlug}`)
            setTimeout(() => {
                setCommandUuid(null)
                setIsUpdating(false)    
            }, 1000);
        }
    }, [command]);

    return (
        <React.Fragment>
            {!isUpdating ? (
                <ButtonGroup>
                    {vm.availableActions.changePowerState ? (
                        <React.Fragment>
                            {vm.status === ApiVmStatus.RUNNING ? (
                                <React.Fragment>
                                    <Button id="restartBtn" color="success" aria-label="Restart" title="Restart" className="btn btn-icon btn-soft-success" onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.SHUTDOWN)}>
                                        <i className="mdi mdi-restart"></i>
                                    </Button>
                                    <Button color="danger" aria-label="Shutdown" title="Shutdown" className="btn btn-icon btn-soft-danger" onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.SHUTDOWN)}>
                                        <i className="mdi mdi-crop-square"></i>
                                    </Button>
                                    <UncontrolledButtonDropdown id="btnGroupVerticalDrop1">
                                        <DropdownToggle color="light" caret className='btn-icon'>

                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.HALT)}> <i className="lab las la-exclamation-triangle"></i> Stop </DropdownItem>
                                            <DropdownItem onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.RESET)}> <i className="mdi mdi-restart-alert"></i> Reset </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </React.Fragment>
                            ) : vm.status === ApiVmStatus.STOPPED ? (
                                <Button color="soft-success" className="btn-icon" onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.START)}>
                                    <i className="lab las la-play"></i>
                                </Button>
                            ) : (
                                null
                            )}
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <Button color="soft-light" disabled>
                                Unavailable
                            </Button>
                        </React.Fragment>
                    )}
                </ButtonGroup>
            ):(
                <GalliumSpinnerButton color='soft-primary'/>
            )}
        </React.Fragment>
    )
}

export default VMListControls;