import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';

const GalliumSizeInput = ({name}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);
    const [multiplier, setMultiplier] = useState(1024);

    const handleSizeChange = (e) => {
        const size = e.target.value;
        setFieldValue(name, size * multiplier);
    };

    const handleMultiplierChange = (e) => {
        const newMultiplier = e.target.value;
        setMultiplier(newMultiplier);
        setFieldValue(name, field.value * newMultiplier);
    };

    return (
        <div className="input-group">
            <input 
                type="number"
                className="form-control"
                placeholder="Size"
                onChange={handleSizeChange}
                value={field.value / multiplier || ''}
            />
            <select 
                className="input-group-text" 
                value={multiplier}
                onChange={handleMultiplierChange}
            >
                <option value={1}>MB</option>
                <option value={1024}>GB</option>
                <option value={1048576}>TB</option>
            </select>
        </div>
    );
};

export default GalliumSizeInput;