import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BreadCrumb from '../../../Components/Common/Breadcrumb';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";

import { useJoinHost } from '../../../GalliumAPIHooks/Host/HostHooks';

import { GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';

import ErrorAlert from 'Components/Gallium/ErrorHelper';
import JoinInstructions from './joinInstructions';
import { JoinHostRequest, JoinHostResponse, GalliumApiErrorResponse } from 'generated';

const AddHypervisor = () => {

    // React Utilities
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    // Gallium API Hooks
    const { trigger, isMutating } = useJoinHost()

    // Form Handlers
    const [errorObject, setErrorObject] = useState(undefined)

    const handleJoinHostFail = (error: GalliumApiErrorResponse) => {
        addHypervisorFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleJoinHostSuccess = (data: JoinHostResponse) => {
        //@ts-ignore
        window.Intercom('trackEvent', 'added-hypervisor');
        navigate(`/hypervisors/${data.slug}`);
    }

    const handleJoinHostRequest = (values: JoinHostRequest) => {
        const options = {
            onError(error: object) {
                handleJoinHostFail(error)
            },
            onSuccess(data: JoinHostResponse) {
                handleJoinHostSuccess(data)
            },
            revalidate: false
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const addHypervisorFormik = useFormik({

        initialValues: {
            name: '',
            joinCode: searchParams.get("joinCode") || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter Name"),
            joinCode: Yup.string()
                .required("Please enter a Join Code")
                .test('isValidJoinCode', 'Invalid Join Code', value => {
                    // Remove full stops or dashes
                    const cleanedValue = (value || '').replace(/[.-]/g, '');

                    // Check if the cleaned value is 9 alphanumeric characters long
                    return /^[a-zA-Z0-9]{9}$/i.test(cleanedValue);
                }),
        }),
        onSubmit: (values) => {
            handleJoinHostRequest(values);
        },
    });

    document.title = "Add Hypervisor | Gallium";
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Add Hypervisor" pageTitle="Hypervisors" />
                    
                    <Row>
                        
                        <Col lg={5}>
                            <FormikProvider value={addHypervisorFormik}>

                                <Card>
                                    <CardBody>
                                        <ErrorAlert errorObj={errorObject} />

                                        <Field
                                            label="Hypervisor Name"
                                            name="name"
                                            id="name"
                                            type="text"
                                            className="mb-3"
                                            placeholder="Friendly Name"
                                            component={GalliumInput}
                                        />

                                        <Field
                                            label="Join Code"
                                            name="joinCode"
                                            id="joinCode"
                                            type="text"
                                            className="mb-3"
                                            placeholder="XXX.XXX.XXX"
                                            component={GalliumInput}
                                        />

                                        <div className="text-end">
                                            <GalliumSubmitButton formik={addHypervisorFormik} color="success" spinner={isMutating}>
                                                Add Hypervisor
                                            </GalliumSubmitButton>
                                        </div>
                                    </CardBody>
                                </Card>      
                            
                            </FormikProvider>
                        </Col>
                        <Col lg={7}>
                            <JoinInstructions />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddHypervisor;