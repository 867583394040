import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';


import DeleteModal from "../../../../Components/Common/DeleteModal";

import TableContainer from 'Components/Gallium/TableContainerNew';

import {
    Modal,
    ModalBody,
    ModalHeader,
    Col,
    Row,
    Badge,
    CardBody,
    Card,
} from "reactstrap"

import { SshKey } from 'generated';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useDeleteUserFromOrg, useGetOrgInfo } from '../../../../GalliumAPIHooks/Org/OrgHooks';
import StringTidy from '../../../../Components/Gallium/StringTidy';
import { OrgUserDetail } from '../../../../generated/models/OrgUserDetail';

const UsersListCard = () => {
    // Get the User List
    const { data, error, isLoading } = useGetOrgInfo()
    
    // Delete User Modal
    const { trigger: deleteUserTrigger, error: deleteUserError, isMutating: isUserMutating } = useDeleteUserFromOrg()

    const [showDeleteModal, setShowDeleteModal] = useState<boolean | undefined>(false);

    const [selectedUser, setSelectedUser] = useState<OrgUserDetail | undefined>(undefined);  
    
    const onClickDelete = (user: OrgUserDetail) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (selectedUser) {
            deleteUserTrigger(selectedUser.slug);
            setShowDeleteModal(false);
        }
    }

    // View User Modal 
    const [showViewModal, setShowViewModal] = useState<boolean | undefined>(false);

    const toggle = useCallback(() => {
        if (showViewModal) {
            setShowViewModal(false);
            setSelectedUser(undefined);
        } else {
            setShowViewModal(true);
        }
    }, [showViewModal]);

    const onUserClick = (key: SshKey) => {
        setSelectedUser(key)
        setShowViewModal(true);
        toggle();
    };


    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Link to="#" className="fw-medium link-primary" onClick={() => { onUserClick(cellProps.row.original) }}>{cellProps.getValue()}</Link>
                        </React.Fragment>
                    );
                },
            },
            {
                header: "Email",
                accessorKey: "email",
                enableColumnFilter: false,
                className: "col-4",
                cell: (cellProps) => {
                    return (cellProps.getValue());
                },
            },
            {
                header: "MFA",
                accessorKey: "mfaEnabled",
                enableColumnFilter: false,
                className: "col-1 text-center",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            {cellProps.getValue() === true ?
                                <span className="text-success"><i className="ri-checkbox-circle-line fs-17 align-middle"></i></span>
                                :
                                <span className="text-muted"><i className="ri-close-circle-line fs-17 align-middle"></i></span>
                            }
                        </React.Fragment>
                    );
                },
            },
            // {
            //     header: "Last Login",
            //     accessorKey: "lastLogin",
            //     enableColumnFilter: false,
                // className: "col-4",
            //     cell: (cellProps) => {
            //         return <DateAdded {...cellProps} />;
            //     },
            // },
            {
                header: "Role",
                accessorKey: "role",
                enableColumnFilter: false,
                className: "col-2 text-center",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <Badge color='primary'>{StringTidy(cellProps.getValue())}</Badge>
                        </React.Fragment>
                    )
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-1 text-end",
                cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <div className="fs-15">
                                <Link to="#" className="remove-item-btn" onClick={() => {const user = cellProps.row.original; onClickDelete(user) }}>
                                    <span className='text-muted text'>
                                        <i className="ri-delete-bin-5-line"></i>
                                    </span>
                                </Link>
                            </div>
                        </React.Fragment>
                    )
                },
            },
        ],[]
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={showDeleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setShowDeleteModal(false)}
                recordName={selectedUser?.email || "Unknown"}
                isWorking={isUserMutating}
            />

            <Modal
                isOpen={showViewModal}
                toggle={toggle}
                centered
                size="md"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-info-subtle" toggle={toggle}>
                View User
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row className="g-3">
                        <Col lg={12}>
                            <h6 className="mb-3 fw-semibold">Name</h6>
                            <p>{selectedUser?.name || "Unknown"}</p>
                            <h6 className="mb-3 fw-semibold">Email</h6>
                            <p>{selectedUser?.email || "Unknown"}</p>
                            <h6 className="mb-3 fw-semibold">Role</h6>
                            <p>{StringTidy(selectedUser?.role || "Unknown")}</p>
                            {/* <h6 className="mb-3 fw-semibold">Role</h6>
                            <p>{formatDate(selectedUser?.addedAt) || "Unknown"}</p> */}
                            <h6 className="mb-3 fw-semibold">MFA Enabled</h6>
                            <p>{selectedUser?.mfaEnabled ? "Yes" : "No" || "Unknown"}</p>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <Card>
                    <CardBody className="card-body pt-0">
                        <TableContainer
                            columns={columns}
                            data={(data.users || [])}
                            isGlobalFilter={true}
                            customPageSize={20}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            SearchPlaceholder='Search for a User...'
                        />
                    </CardBody>
                </Card>
            )}            
        </React.Fragment>
    );
};

export default UsersListCard;