


import React from 'react';

import Loader from 'Components/Gallium/Loader';

import ErrorNotice from 'Components/Gallium/ErrorNotice';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import HypervisorMetrics from './Metrics';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import HypervisorMemoryUsageDetailed from './MemoryUsageDetailed';
import HypervisorStorageWarning from '../Warnings/StorageWarning';
import HypervisorKVMWarning from '../Warnings/KVMWarning';
import HypervisorUpdateWarning from '../Warnings/UpdateWarning';
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { HostDetail } from 'generated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';


type HypervisorQuickInfoProps = {
  hypervisorDetail: HostDetail;
};

const HypervisorQuickInfo: React.FC<HypervisorQuickInfoProps> = ({ hypervisorDetail }) => {
    const { t } = useTranslation()

    return (
        <Row>
            <Col>
                <Card className='quick-info-widget mb-2'>
                    <CardBody className='p-0'>
                        <div className='row row-cols-md-3 row-cols-1 g-0'>
                            <Col>
                                <ListGroup className="list-group-flush border-dashed pt-0">
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 fw-semibold">
                                        {hypervisorDetail.name}
                                    </ListGroupItem>
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                        {hypervisorDetail.description || "No Description"}
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup className="list-group-flush border-dashed pt-0">

                                    {hypervisorDetail.hwDescription ? (
                                        <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["server"]} className='me-2'/>
                                            {hypervisorDetail.hwDescription} 
                                        </ListGroupItem>
                                    ):(null)}    
                                    
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["microchip"]} className='me-2'/>
                                        {hypervisorDetail.hwDescriptionCpu} ({hypervisorDetail.hwTotalCores}C/{hypervisorDetail.hwTotalThreads}T)
                                    </ListGroupItem>
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["memory"]} className='me-2'/>
                                        {hypervisorDetail.hwDescriptionRam}
                                    </ListGroupItem>

                                    {hypervisorDetail.hwSerialNumber ? (
                                        <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["rectangle-barcode"]} className='me-2'/>
                                            {hypervisorDetail.hwSerialNumber} 
                                        </ListGroupItem>
                                    ):(null)}    

                                    

                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup className="list-group-flush border-dashed pt-0">
                                    {hypervisorDetail.hwAssetTag ? (
                                        <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                            <FontAwesomeIcon icon={byPrefixAndName.fal["tag"]} className='me-2'/>
                                            {hypervisorDetail.hwAssetTag} 
                                        </ListGroupItem>
                                    ):(null)}    
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["diagram-project"]} className='me-2'/>
                                        LAN IP: {hypervisorDetail.netIpv4Lan}
                                    </ListGroupItem>
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">
                                        <FontAwesomeIcon icon={byPrefixAndName.fal["ethernet"]} className='me-2'/>
                                        MAC: {hypervisorDetail.netMac}
                                    </ListGroupItem>
                                    <ListGroupItem tag="a" to="#" className="list-group-item-action ps-2 p-1 fs-12 text-muted">

                                        <FontAwesomeIcon icon={byPrefixAndName.fal["globe"]} className='me-2'/>
                                        Public IP: {hypervisorDetail.netIpv4Public}
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
};

export default HypervisorQuickInfo