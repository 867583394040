import React, { useState } from 'react';

import VolumeEditModal from './VolumeEditModal';
import { HostVolume } from 'generated';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import { Link } from 'react-router-dom';

interface VolumeEditButtonProps {
    hostSlug: string
    volume: HostVolume
}

const VolumeEditButton: React.FC<VolumeEditButtonProps> = ({ hostSlug, volume }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    return (
        <React.Fragment>
            <VolumeEditModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                hostSlug={hostSlug}
                volume={volume}
            />
            
            <Link to="#" className='ms-2 text-muted' onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={byPrefixAndName.fal["pen"]}/> 
            </Link>


        </React.Fragment>
    )
};

export default VolumeEditButton;