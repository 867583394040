import React, { useState } from 'react';

import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import {
    Label,
    Col,
} from "reactstrap"

import { ConfigureVmAutoStart, GalliumApiErrorResponse, VirtualMachineDetail } from 'generated';
import { useModifyVirtualMachineConfig } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import StringTidy from 'Components/Gallium/StringTidy';
import { toast } from 'react-toastify';
import ErrorAlert from 'Components/Gallium/ErrorHelper';


const EditAutostart = ({ virtualMachine }: {virtualMachine: VirtualMachineDetail}) => {

    const {trigger, isMutating} = useModifyVirtualMachineConfig(virtualMachine.slug)

    const [errorObject, setErrorObject] = useState(undefined)

    const handleVirtualMachineChangeFail = (error: GalliumApiErrorResponse) => {
        editVirtualMachineAutostartFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleVirtualMachineChangeSuccess = () => {
        toast.info("Submitted Virtual Machine Change")
    }

    const handleVirtualMachineChangeRequest = (values) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachineChangeFail(error)
            },
            onSuccess() {
                handleVirtualMachineChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    // Formik
    const editVirtualMachineAutostartFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            autoStart: virtualMachine.autoStart,
        },
        validationSchema: Yup.object({
            autoStart: Yup.string().oneOf(Object.values(ConfigureVmAutoStart), "Invalid Auto Start Option").required("Auto Start Option is Required")
        }),
        onSubmit: (values) => {

            const editValues = {
                autoStart: values["autoStart"],
            };
            // @ts-ignore
            window.Intercom('trackEvent', 'edit-vm-request');
            // save new key
            handleVirtualMachineChangeRequest(editValues)
        },
    });
    
    return (
        <React.Fragment>
            <FormikProvider value={editVirtualMachineAutostartFormik}>

                <ErrorAlert errorObj={errorObject} />

                <div className="mt-0 mb-3">
                    <Label className="form-label" htmlFor="autoStartField">Action after Hypervisor Starts</Label>

                    <Col md={6}>
                        <Field
                            id="autoStartField"
                            name="autoStart"
                            as="select"
                            className="form-select mb-3"
                        >
                            {Object.values(ConfigureVmAutoStart).map((item, index) =>(
                                <option value={item} key={index}>{StringTidy(item)}</option>
                            ))}
                        </Field>
                    </Col>

                </div>
                        
                <div className='float-end'>
                    <GalliumSubmitButton formik={editVirtualMachineAutostartFormik} spinner={isMutating} color="success"> Submit</GalliumSubmitButton>
                </div>

            </FormikProvider>
        </React.Fragment>
    )
};

export default EditAutostart;
