import React, { useState } from 'react';


import * as Yup from "yup";

import { useFormik, FormikProvider, Field } from "formik";

import {
    Col,
    Row,
    Alert,
    Card,
    CardBody,
    Button,
} from "reactstrap"

import classnames from "classnames";

import { ApiVmStatus, ConfigureVmNicAction, GalliumApiErrorResponse, VirtualMachineDetail } from 'generated';

import { useModifyVirtualMachineConfig } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';

import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import { toast } from 'react-toastify';
import ErrorAlert from 'Components/Gallium/ErrorHelper';


const EditNetworkAdaptors = ({ virtualMachine }: {virtualMachine: VirtualMachineDetail}) => {

    const {trigger, isMutating} = useModifyVirtualMachineConfig(virtualMachine.slug)
    const { data: hypervisor, error: hypervisorError, isLoading: isHypervisorLoading } = useGetHost(virtualMachine.host.slug);

    const [errorObject, setErrorObject] = useState(undefined)

    const handleVirtualMachineChangeFail = (error: GalliumApiErrorResponse) => {
        editVirtualMachineHardwareFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleVirtualMachineChangeSuccess = () => {
        toast.info("Submitted Virtual Machine Change")
    }

    const handleVirtualMachineChangeRequest = (values) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleVirtualMachineChangeFail(error)
            },
            onSuccess() {
                handleVirtualMachineChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const initialInterfaceValues = virtualMachine.networkInterfaces.map((item) => ({
        ...item,
        action: "NONE",
    }));

    // Formik
    const editVirtualMachineHardwareFormik = useFormik({
        initialValues: {
            networkAdaptors: initialInterfaceValues
        },
        validationSchema: Yup.object({
            networkAdaptors: Yup.array()
                .of(
                    Yup.object().shape({
                        hostNetworkSlug: Yup.string().required("You must specify a host network"),
                        // other field validations...
                        action: Yup.string().required(),
                    })
                )
                .test('has-valid-action', 'At least one interface is required', (array) => {
                    return array.some(({ action }) => ["NONE", ConfigureVmNicAction.CREATE].includes(action));
                })
                .test('max-networkAdaptors', 'There can be only 4 Network Adaptors', (array) => {
                    let count = 0;
                    array.forEach(({ action }) => {
                        if (["NONE", ConfigureVmNicAction.CREATE].includes(action)) count++;
                        if (action === ConfigureVmNicAction.DESTROY) count--;
                    });
                    return count <= 4;
                })
                .test('unique-hostNetworkSlug', 'Only one adaptor can be connected to each host network', (array) => {
                    const slugSet = new Set(array.map(item => item.hostNetworkSlug));
                    return slugSet.size === array.length;
                })
                

        }),
        onSubmit: (values) => {

            const submissionArray = values.networkAdaptors.reduce((acc, adaptor) => {
                if(adaptor.action === ConfigureVmNicAction.DESTROY) {
                    acc.push({ interfaceSlug: adaptor.interfaceSlug, action: ConfigureVmNicAction.DESTROY });
                } 
                else if(adaptor.action === ConfigureVmNicAction.CREATE) {
                    acc.push({ hostNetworkSlug: adaptor.hostNetworkSlug, action: ConfigureVmNicAction.CREATE });
                }
                return acc;
            }, []);
            
            const editInterfacesRequest = {"networkInterfaces": submissionArray};
            // @ts-ignore
            window.Intercom('trackEvent', 'edit-vm-request');
            handleVirtualMachineChangeRequest(editInterfacesRequest)
        },
    });

    const pageDisabled: boolean = virtualMachine.status === ApiVmStatus.STOPPED ? false : true

    const handleNetworkAdaptorRemove = (index) => {
        const networkAdaptorsArray = editVirtualMachineHardwareFormik.values.networkAdaptors.slice(); // Create a copy of the array
        networkAdaptorsArray.splice(index, 1); // Remove the element at the specified index
        editVirtualMachineHardwareFormik.setFieldValue('networkAdaptors', networkAdaptorsArray); // Update the field value
    };
  
    const handleNetworkAdaptorAdd = () => {
        const networkAdaptorsArray = [...editVirtualMachineHardwareFormik.values.networkAdaptors, {action: ConfigureVmNicAction.CREATE}];
        editVirtualMachineHardwareFormik.setFieldValue('networkAdaptors', networkAdaptorsArray);
    };

    const handleExistingNetworkAdaptorAction = (index: number, action: ConfigureVmNicAction | "NONE") => {
        const networkAdaptorsArray = editVirtualMachineHardwareFormik.values.networkAdaptors.slice(); // Create a copy of the array
        networkAdaptorsArray[index].action = action
        editVirtualMachineHardwareFormik.setFieldValue('networkAdaptors', networkAdaptorsArray); // Update the field value
    };

    return (
        <React.Fragment>
            {isHypervisorLoading ? (<Loader />) : hypervisorError ? (<ErrorNotice />) : (
                <FormikProvider value={editVirtualMachineHardwareFormik}>
                    {pageDisabled ? (<Alert color="info">You cannot edit these settings whilst the Virtual Machine is in the current state</Alert>):(null)}
                    <ErrorAlert errorObj={errorObject} />
                        
                    <div className="mt-0 mb-3 pt-2">
                        <Row className='gy3'>

                            {editVirtualMachineHardwareFormik.values.networkAdaptors.map((item, key) => (
                                item.action !== ConfigureVmNicAction.CREATE ? (
                                    <Col md={12} key={key}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-flex align-items-center">

                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className={classnames("fs-13 mb-1", { "text-decoration-line-through": item.action === ConfigureVmNicAction.DESTROY, })}>
                                                            Interface {item.macAddress}
                                                        </h5>
                                                        <div className={classnames("text-muted fs-12 mb-0", { "text-decoration-line-through": item.action === ConfigureVmNicAction.DESTROY, })}>
                                                            <i className="mdi mdi-subdirectory-arrow-right"></i> 
                                                            Connected to: {item.networkName}
                                                        </div>
                                                    </div>
                                                    {pageDisabled ? (null):(
                                                        <div className="flex-shrink-0 ms-2">
                                                            <div className="d-flex gap-1">
                                                                {item.action === ConfigureVmNicAction.DESTROY ? (
                                                                    <button type="button" className="btn btn-icon text-muted btn-sm fs-18"
                                                                        onClick={() => handleExistingNetworkAdaptorAction(key, "NONE")}
                                                                    >
                                                                        <i className="lab las la-trash-restore-alt"></i>
                                                                    </button>
                                                                ):(
                                                                    <button type="button" className="btn btn-icon text-muted btn-sm fs-18"
                                                                        onClick={() => handleExistingNetworkAdaptorAction(key, ConfigureVmNicAction.DESTROY)}
                                                                    >
                                                                        <i className="lab  las la-trash-alt"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ) : (
                                    <Col md={12} key={key}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-flex flex-column h-100">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted mb-3"><i className="lab las la-ethernet"></i> New Adaptor</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <button type="button" className="btn-close float-end fs-11" aria-label="Close" onClick={() => handleNetworkAdaptorRemove(key)}></button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Field
                                                        id={`networkAdaptors${key}`}
                                                        name={`networkAdaptors[${key}].hostNetworkSlug`}
                                                        as="select"
                                                        className="form-select mb-0"
                                                    >
                                                        <option>Please Select Network</option>
                                                        {hypervisor?.availableNetworks.map((item, key) => (
                                                            <>
                                                                <option value={item.slug} key={key}>{item.name}</option>
                                                                {item.children && item.children.map((child, childKey) => (
                                                                    <option value={child.slug} key={`child-${childKey}`}>-- {child.name}</option>
                                                                ))}
                                                            </>
                                                        ))
                                                        }
                                                    </Field>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            ))}
                            <Col md={12} >
                                {pageDisabled ? (null):(
                                    <Card className="border card-border-light">
                                        <CardBody>
                                            <div className="d-flex flex-column h-100">
                                                <Button color="soft-primary" className="mb-0" onClick={() => handleNetworkAdaptorAdd()}>Add Adaptor</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )}
                            </Col>
                        </Row>
                        {pageDisabled ? (null):(
                            <div className='float-end'>
                                <GalliumSubmitButton formik={editVirtualMachineHardwareFormik} spinner={isMutating} color="success"> Submit</GalliumSubmitButton>
                            </div>
                        )}
                    </div>

                    

                </FormikProvider>
            )}
        </React.Fragment>
    )
};

export default EditNetworkAdaptors;
