import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import BreadCrumb from '../../../../Components/Common/Breadcrumb';

import { useDispatch } from "react-redux";

import withRouter from "../../../../Components/Common/withRouter";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";

import classnames from "classnames";

import CreateTemplateStepOne from './StepOne';
import StepTwo from './StepTwo';

const CreateTemplate = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();

    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);

    useEffect(() => {
    //   dispatch(onGetHypervisorList());
    }, [dispatch]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            const modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    // No redux in this component but we jump to step 2 if the draft template already exists and has been provided in the URL
    useEffect(() => {
        // Effect logic (runs after the component mounts)
        // Jump to tab 2 if this is an existing template
        if (searchParams.get("template") !== null) {
            toggleTab(2);
        }
        // Cleanup logic (runs after the component unmounts)
        return () => {
            // Nothing to Do
        };
    }, [searchParams]);

    document.title = "Create Template | Gallium";
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create Template" pageTitle="Inventory" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody className="checkout-tab">
                                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                        <Nav
                                            className="nav-pills nav-justified custom-nav"
                                            role="tablist"
                                        >
                                            <NavItem role="presentation">
                                                <NavLink href="#"
                                                    className={classnames({ active: activeTab === 1, done: (activeTab <= 3 && activeTab >= 0) }, "p-3 fs-15")}
                                                >
                                                    <i className="ri-server-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                    Basics
                                                </NavLink>
                                            </NavItem>
                                            <NavItem role="presentation">
                                                <NavLink href="#"
                                                    className={classnames({ active: activeTab === 2, done: activeTab <= 3 && activeTab > 1 }, "p-3 fs-15")}
                                                >
                                                    <i className="ri-hard-drive-line fs-16 p-2 bg-primary-subtle text-primary rounded-circle align-middle me-2"></i>
                                                    Disk Configuration
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1} id="pills-bill-info">
                                            {searchParams.get("template") !== null ? (null):(
                                                <CreateTemplateStepOne />
                                            )}
                                        </TabPane>

                                        <TabPane tabId={2}>

                                            <StepTwo />

                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(CreateTemplate);