// @ts-nocheck
import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader'

import {
    Card,
    CardBody,
    Badge,

} from "reactstrap"
import { deleteTemplateDisk, getTemplateDisk } from 'store/actions';
import { useDispatch } from 'react-redux';

import Preview from './UploadPreview';
import DeleteModal from 'Components/Common/DeleteModal';
import { TemplateDisk as TemplateDiskType } from 'generated';



const UploadDisk = ({ disk }) => {
    const dispatch = useDispatch();

    const [deleteModal, setDeleteModal] = useState(false)

    const handleDeleteDisk = (disk: TemplateDiskType) => {
        dispatch(deleteTemplateDisk(disk))
        setDeleteModal(false)
    }

    const DiskUploader = ({disk}) => {
        // specify upload params and url for your files
        const getUploadParams = ({ meta }) => { return { url: disk.uploadUrl } }
        
        // called every time a file's `status` changes
        const handleChangeStatus = ({ meta, file }, status) => { 
            if (status === 'done') {
                dispatch(getTemplateDisk(disk.templateSlug, disk.slug))
            }
        }

        const classNames = {
            dropzone: "card card-body border-dashed mb-0",
            inputLabel: "text-muted text-center mb-2 p-2",
            preview: ""
        }

        const styles = {
            input: {
                display: "none"
            }
        };

        const accept = (diskType) => {
            if (diskType === "DISK") {
                return ".qcow2";
            } else {
                return ".iso";
            }
        };

        const inputContent = () => {
            return (
                <React.Fragment>
                    <i className="ri-uninstall-line label-icon align-middle"></i> Click to Upload ({accept(disk.diskType)})
                </React.Fragment>
            )
        }

        return (
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                accept={accept(disk.diskType)}
                maxFiles={1} 
                multiple={false}
                classNames={classNames}
                inputContent={inputContent}
                PreviewComponent={Preview}
                styles={styles}
            />
        )
    }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteDisk(disk)}
                onCloseClick={() => setDeleteModal(false)}
                recordName='this disk'
            />
            <Card>
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {disk.diskType === "DISK" ? (
                                <p className="text-muted mb-2"><i className="lab las la-hdd"></i> Upload Disk</p>
                            ):(
                                <p className="text-muted mb-2"><i className="ri-album-line align-middle"></i> Upload ISO</p>
                            )}
                        </div>
                        <div className="flex-shrink-0 text-end">
                            <Badge color="primary"> Pending </Badge>
                            <button type="button" className="btn avatar-xs p-0" onClick={() => setDeleteModal(true)}>
                                <i className="ri-delete-bin-7-line text-muted"></i>
                            </button>
                        </div>
                    </div>
                    <DiskUploader disk={disk}/>
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

const BlankDisk = ({ disk }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const dispatch = useDispatch()

    const handleDeleteDisk = (disk: TemplateDiskType) => {
        dispatch(deleteTemplateDisk(disk))
        setDeleteModal(false)
    }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteDisk(disk)}
                onCloseClick={() => setDeleteModal(false)}
                recordName='this disk'
            />
            <Card>
                <CardBody>
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted mb-4"><i className="lab las la-hdd"></i> Blank Disk</p>
                            </div>
                            <div className="flex-shrink-0 text-end align-middle">
                                <button type="button" className="btn avatar-xs p-0" onClick={() => setDeleteModal(true)}>
                                    <i className="ri-delete-bin-7-line text-muted"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <dl className="row mb-0">
                        <dt className="col-sm-3">Minimum Size</dt>
                        <dd className="col-sm-9">{disk.minSizeMb} MB</dd>
                    </dl>

                </CardBody>
            </Card>
        </React.Fragment>
    )
};

const UploadedDisk = ({ disk }) => {

    const [deleteModal, setDeleteModal] = useState(false)
    const dispatch = useDispatch()

    const handleDeleteDisk = (disk: TemplateDiskType) => {
        dispatch(deleteTemplateDisk(disk))
        setDeleteModal(false)
    }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteDisk(disk)}
                onCloseClick={() => setDeleteModal(false)}
                recordName='this disk'
            />
            <Card>
                <CardBody>

                    <div className="d-flex">
                        <div className="flex-grow-1">
                            {disk.diskType === "DISK" ? (
                                <p className="text-muted mb-2"><i className="lab las la-hdd"></i> Uploaded Disk</p>
                            ):(
                                <p className="text-muted mb-2"><i className="ri-album-line align-middle"></i> Uploaded ISO</p>
                            )}
                        </div>
                        <div className="flex-shrink-0 text-end align-middle">
                            <Badge color="success"> Ready </Badge>
                            <button type="button" className="btn avatar-xs p-0" onClick={() => setDeleteModal(true)}>
                                <i className="ri-delete-bin-7-line text-muted"></i>
                            </button>
                        </div>
                    </div>

                    <dl className="row mb-0">
                        <dt className="col-sm-3">File Name</dt>
                        <dd className="col-sm-9">{disk.filename}</dd>

                        <dt className="col-sm-3">SHA 256</dt>
                        <dd className="col-sm-9"><code>{disk.sha256sum}</code> </dd>
                    </dl>
                    
                </CardBody>
            </Card>
        </React.Fragment>
    )
};


const TemplateDisk = ({ disk }) => {
    if (disk.blank === false && disk.filename === null) {
        return <UploadDisk disk={disk} />;
    } else if (disk.blank === false && disk.filename) {
        return <UploadedDisk disk={disk} />;
    } else {
        return <BlankDisk disk={disk} />;
    }
};

export default React.memo(TemplateDisk);
