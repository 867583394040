import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Card, CardBody, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { AlertProfile, AlertProfileAddOrUpdateRequest, GalliumApiErrorResponse } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';

import { useUpdateAlertProfile } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import AlertProfilesWebhooksComponent from '../Add/WebhooksComponent';
import AlertProfilesExistingUserComponent from '../Add/ExistingUserComponent';
import AlertProfilesRemindersComponent from '../Add/RemindersComponent';
import AlertProfilesNotificationEventsComponent from '../Add/NotificationEventsComponent';
import AlertProfilesRulesEventsComponent from '../Add/AlertRulesComponent';
import AlertProfilesEmailComponent from '../Add/EmailComponent';

interface EditAlertProfileFormProps {
    alertProfile: AlertProfile;
}

const EditAlertProfileForm: React.FC<EditAlertProfileFormProps> = ({alertProfile}) => {
    const { trigger, isMutating } = useUpdateAlertProfile(alertProfile.slug)
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handleEditAlertProfileFail = (error: GalliumApiErrorResponse) => {
        editAlertProfileFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleEditAlertProfileSuccess = () => {
        toast.success("Alert Profile Updated")
        navigate("/settings/alerts");
    }

    const handleEditAlertProfileRequest = (values: AlertProfileAddOrUpdateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleEditAlertProfileFail(error)
            },
            onSuccess() {
                handleEditAlertProfileSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const editAlertProfileFormik = useFormik({
        initialValues: {
            name: alertProfile.name,
            emails: alertProfile.emails,
            webhookUrls: alertProfile.webhookUrls,
            webhookVerifySsl: alertProfile.webhookVerifySsl,
            userSlugs: alertProfile.users.map(user => user.slug) || [],
            alertRules: alertProfile.alertRules,
            notifyEvent: alertProfile.notifyEvents || [],
            reminderThresholdMinutes: alertProfile.reminderThresholdMinutes
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please Enter a Name"),
            
            emails: Yup.array()
                .of(Yup.string().email("Invalid email address"))
                .nullable(),
            
            webhookUrls: Yup.array()
                .of(Yup.string().url("Invalid URL"))
                .nullable(),

            userSlugs: Yup.array()
                .of(Yup.string())
                .nullable(),

            alertRules: Yup.array()
                .of(Yup.object().shape({
                    // ... you can define individual field validations here if needed
                }))
                .min(1, "One alert rule must be selected")
                .required("One alert rule must be selected"),

            reminderThresholdMinutes: Yup.number()
        }),
        onSubmit: (values) => {
            handleEditAlertProfileRequest(values)
        },
    });

    return (    
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <FormikProvider value={editAlertProfileFormik}>
                        <Card>
                            <CardBody>
                                <ErrorAlert errorObj={errorObject} />
                        
                                <GalliumFormHeader icon="alert-circle" title="Alert Profile Settings">
                                    <Col md={6} className="mb-3">
                                        <Field
                                            name="name"
                                            id="name"
                                            placeholder="Please enter a name for this alert profile"
                                            type="text"
                                            label="Name"
                                            component={GalliumInput}
                                        />
                                    </Col>
                                    <AlertProfilesRulesEventsComponent />
                                </GalliumFormHeader>
                                    
                                <GalliumFormHeader icon="send" title="Notifications">
                                    <AlertProfilesExistingUserComponent />
                                    <AlertProfilesWebhooksComponent />
                                    <AlertProfilesEmailComponent />
                                    <AlertProfilesRemindersComponent />
                                    <AlertProfilesNotificationEventsComponent />
                                </GalliumFormHeader>
                            
                                
                                <GalliumSubmitButton className="float-end" formik={editAlertProfileFormik} spinner={isMutating} color="success">
                                    Update Alert Profile
                                </GalliumSubmitButton>
                            </CardBody>
                        </Card>
                    </FormikProvider>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default EditAlertProfileForm;