import TableContainer from 'Components/Gallium/TableContainerNew';
import { IncidentActions, IncidentHost, IncidentOpenedAt, IncidentStatus, IncidentType } from 'pages/Monitoring/List/IncidentListCol';
import React, { useMemo } from 'react';


import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"

const AllIncidentList = ({data}) => {

    const columns = useMemo(
        () => [
            {
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: false,
                className: "col-1",
                cell: (cellProps) => {
                    return <IncidentStatus {...cellProps} />;
                },
            },
            {
                header: "Type",
                accessorKey: "alertKey",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <IncidentType {...cellProps} />;
                },
            },
            {
                header: "Opened",
                accessorKey: "openedAt",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <IncidentOpenedAt {...cellProps} />;
                },
            },
            {
                header: "Closed",
                accessorKey: "closeAt",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <IncidentOpenedAt {...cellProps} />;
                },
            },
            {
                header: "Hypervisor",
                accessorKey: "host",
                enableColumnFilter: false,
                className: "col-2",
                cell: (cellProps) => {
                    return <IncidentHost {...cellProps} />;
                },
            },
            {
                header: "",
                accessorKey: "slug",
                enableColumnFilter: false,
                className: "col-3",
                cell: (cellProps) => {
                    return <IncidentActions {...cellProps} />;
                },
            }
        ],[]
    );

    const sorting = useMemo(
        () => [
            {
                id: 'openedAt',
                desc: true, 
            },
        ],[]
    );

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card id="serviceGatewayList">
                        <CardBody className="pt-0">
                            <TableContainer
                                columns={columns}
                                data={(data || [])}
                                isGlobalFilter={true}
                                customPageSize={20}
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap mb-0"
                                theadClass="table-light table-nowrap"
                                thClass="table-light text-muted"
                                SearchPlaceholder='Search for an Incident...'
                                sorting={sorting}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>  
        </React.Fragment>
    );
};

export default AllIncidentList;