import React, { useState } from 'react';

import {
    Button,
} from "reactstrap"

import AttachDiskModal from './AttachDiskModal';
import { VirtualMachineDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

interface AttachDiskButtonProps {
    virtualMachine: VirtualMachineDetail
}

const AttachDiskButton: React.FC<AttachDiskButtonProps> = ({ virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    const { mutate } = useSWRConfig()

    const openModal = () => {
        mutate(`volumes/${virtualMachine.host.slug}`)
        setShowModal(true)
    }

    return (
        <React.Fragment>
            <AttachDiskModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                virtualMachine={virtualMachine}
            />

            <Button color="soft-primary" className='mb-0' onClick={openModal}>
                <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-arrow-up"]} className='ms-1'/>
                {/* <i className="mdi mdi-plus-circle-outline me-1" /> */}
                {t('translation:virtualMachine:diskAttach')}
            </Button>


        </React.Fragment>
    )
};

export default AttachDiskButton;