import React from "react";
import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSpinnerButton } from "Components/Gallium/GalliumForms";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'
import { GalliumApiErrorResponse } from "generated";

interface DeleteModalProps {
    onCloseClick: () => void;
    onDeleteClick: () => void;
    show: boolean;
    recordName?: string; 
    isWorking?: boolean;
    errorObj?: GalliumApiErrorResponse
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    show,
    onDeleteClick,
    onCloseClick,
    recordName,
    isWorking,
    errorObj
}) => {
    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <FontAwesomeIcon icon={byPrefixAndName.far["trash-xmark"]} className='mt-3 fs-36 text-danger'/>
                    <ErrorAlert errorObj={errorObj} />
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Are you sure ?</h4>
                        <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove {recordName || "this record"}?
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                        onClick={onCloseClick}
                    >
            Close
                    </button>
                    {isWorking ? (
                        <GalliumSpinnerButton color="danger" />
                    ):(
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={onDeleteClick}
                        >
                Yes, Delete It!
                        </button>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DeleteModal;