import React from 'react';

import {
    Col,
} from "reactstrap"
import { Field, useFormikContext } from 'formik';
import { GalliumFormHeader, GalliumInput } from 'Components/Gallium/GalliumForms';

const Basics = () => {
    const formik = useFormikContext();

    return (
        <React.Fragment>
            <GalliumFormHeader icon="info" title="Identification">

                <Col lg={4} className="mt-3 mb-3">
                    <Field
                        name="name"
                        label="VM Name"
                        id="name"
                        className="form-control"
                        placeholder="Name for this VM"
                        type="text"
                        component={GalliumInput}
                    />
                </Col>

                <div className="mt-3 mb-3">
                    <Field
                        name="description"
                        label="Description"
                        id="description"
                        className="form-control"
                        placeholder="Optional - Description of this VM"
                        type="textarea"
                        component={GalliumInput}
                    />                    
                </div>

            </GalliumFormHeader>
        </React.Fragment>
    )
};

export default Basics;
