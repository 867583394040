import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import classnames from "classnames";
import Loader from "../../../Components/Gallium/Loader";

import {
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    CardHeader
} from "reactstrap";

import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import EditHardware from './Hardware';
import EditAutostart from './Autostart';
import EditBasics from './Basics';
import EditNetworkAdaptors from './NetworkAdaptors';

const VirtualMachineEdit = () => {
    const virtualMachineSlug = useParams().id
    const {data: virtualMachine, error, isLoading: isVirtualMachineLoading} = useGetVirtualMachine(virtualMachineSlug)

    // Vertical Nav Tabs
    const [verticalTab, setverticalTab] = useState("NAME");
    const toggleVertical = (tab) => {
        if (verticalTab !== tab) {
            setverticalTab(tab);
        }
    };

    return (    
        <React.Fragment>
            <Col style={{ flex: '0 0 300px' }}>
                {isVirtualMachineLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    <React.Fragment>
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1"><i className="lab las la-edit"></i> Edit Settings</h4>       
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <Nav pills className="flex-column" id="v-pills-tab">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        "mb-2": true,
                                                        active: verticalTab === "NAME",
                                                    })}
                                                    onClick={() => {
                                                        toggleVertical("NAME");
                                                    }}
                                                    id="v-pills-home-tab"
                                                >
                                                            Name
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        "mb-2": true,
                                                        active: verticalTab === "AUTOSTART",
                                                    })}
                                                    onClick={() => {
                                                        toggleVertical("AUTOSTART");
                                                    }}
                                                    id="v-pills-profile-tab"
                                                >
                                                            Autostart
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        "mb-2": true,
                                                        active: verticalTab === "HARDWARE",
                                                    })}
                                                    onClick={() => {
                                                        toggleVertical("HARDWARE");
                                                    }}
                                                    id="v-pills-messages-tab"
                                                >
                                                            Hardware
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        "mb-2": true,
                                                        active: verticalTab === "NETWORK",
                                                    })}
                                                    onClick={() => {
                                                        toggleVertical("NETWORK");
                                                    }}
                                                    id="v-pills-messages-tab"
                                                >
                                                            Network Adaptors
                                                </NavLink>
                                            </NavItem>           
                                        </Nav>
                                    </Col>
                                    <Col md={9}>
                                        <TabContent
                                            activeTab={verticalTab}
                                            className="text-muted mt-4 mt-md-0"
                                            id="v-pills-tabContent"
                                        >
                                            <TabPane tabId="NAME" id="v-pills-home">
                                                <EditBasics virtualMachine={virtualMachine} />
                                            </TabPane>
                                            <TabPane tabId="AUTOSTART" id="v-pills-profile">
                                                <EditAutostart virtualMachine={virtualMachine} />

                                            </TabPane>
                                            <TabPane tabId="HARDWARE" id="v-pills-messages">
                                                <EditHardware virtualMachine={virtualMachine} />
                                            </TabPane>
                                            <TabPane tabId="NETWORK" id="v-pills-messages">
                                                <EditNetworkAdaptors virtualMachine={virtualMachine} />
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </React.Fragment>
                )}
            </Col>
        </React.Fragment>
    );
};

export default VirtualMachineEdit