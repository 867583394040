import React from 'react';

import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import VirtualMachineActionDelete from './ActionDelete';
import { VirtualMachineDetail } from 'generated';

interface VirtualMachineActionsProps {
    virtualMachine: VirtualMachineDetail
}

const VirtualMachineActions: React.FC<VirtualMachineActionsProps> = ({virtualMachine}) => {

    return (
        <React.Fragment>
            <Col style={{ flex: '0 0 300px' }}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1"><i className="lab las la-running"></i> Actions</h4>       
                    </CardHeader>
                    <CardBody>
                        <VirtualMachineActionDelete virtualMachine={virtualMachine} />
                    </CardBody>
                </Card>
            </Col>
        
        </React.Fragment>
    )
};

export default VirtualMachineActions;