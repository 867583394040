// @ts-nocheck
/* eslint-disable */
import React from 'react';

import { useParams } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardBody,
    Col,
    ButtonGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Row,
} from "reactstrap"

import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { useGetHostSensorList } from 'GalliumAPIHooks/Host/HostHooks';
import { SensorListResponse, SensorDetail, HealthStatus, ComponentGroup, SensorGroupType } from 'generated';
import {round} from 'lodash'
import StringTidy from 'Components/Gallium/StringTidy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';

const formatValue = (data: SensorDetail) => {

    let output

    switch (data.unitString) {
    case "%":
        output = round(data.value, 2) + data.unitString || "";
        break;     
    case "A":
        output = round(data.value, 3) + data.unitString || "";
        break;
    case "GB":
        output = round(data.value, 0) + data.unitString || "";
        break;             
    default:
        output = data.value + data.unitString || ""
    }

    return(
        output
    )
}

const SensorBadge = ({data} : {data: SensorDetail}) => {

    let color: string, text: string, icon: string

    const filteredStates = data.states.filter(
        state => state.key !== "UPPER_NON_CRITICAL_GOING_LOW" && state.key !== "UPPER_NON_CRITICAL_GOING_HIGH"  && state.key !== "STATE_DEASSERTED"
    ).map(state => state.name).join(', ') || null;

    switch (data.healthStatus) {
    case HealthStatus.HEALTHY:
        color = "success";
        text = "Healthy";
        icon = "ri-check-line"
        break;
    case HealthStatus.UNHEALTHY:
        color = "danger";
        text = "Unhealthy";
        icon = "ri-close-line"
        break;     
    case HealthStatus.UNSPECIFIED:
        color = "light";
        text = "Unspecified";
        icon = "ri-subtract-line"
        break;                  
    default:
        color = "light";
        text = data.healthStatus;
        icon = "ri-subtract-line"
    }

    return (
        <React.Fragment>
            <ButtonGroup>
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" className={"btn btn-label right btn-sm btn-" + color}>
                        <i className={`${icon} label-icon align-middle fs-16 ms-2`}></i>
                        {data.genDesc ?(data.value ? (formatValue(data)) : filteredStates || text) : (text)}
                        {/* <i className="mdi mdi-chevron-down ps-1"></i> */}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md p-3 dropdownmenu-warning">
                        <div className='dropdown-header noti-title p-0'>
                            <h5 className='fs-12'>Status</h5> 
                        </div>
                        <p>
                            {text}
                        </p>
                        {filteredStates?(
                            <>
                                <div className='dropdown-header noti-title p-0'>
                                    <h5 className='fs-12'>State</h5> 
                                </div>
                                <p>
                                    {filteredStates}
                                </p>
                            </>
                        ):(null)}
                        {data.value ? (
                            <>
                                <div className='dropdown-header noti-title p-0'>
                                    <h5 className='fs-12'>Value</h5> 
                                </div>
                                <p>
                                    {formatValue(data)}
                                </p>
                            </>
                        ) : (null)}
                        <DropdownItem divider />
                        <div className='dropdown-header noti-title p-0'>
                            <h5 className='fs-12'>Thresholds</h5>
                        </div>
                        {data.thresholdLow ? (<p className='mb-1'>Low: {data.thresholdLow} {data.unitString}</p>):(null)}
                        {data.thresholdHigh ? (<p>High: {data.thresholdHigh} {data.unitString}</p>):(null)}
                        <DropdownItem divider />
                        <DropdownItem active className='text-center'>
                            Disable This Item
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ButtonGroup>
        </React.Fragment>
    )
};

const ComponentDetail = ({data} : {data: ComponentGroup}) => {

    return (
        <React.Fragment>
            <Card className="mb-2">
                <CardHeader className='p-2 ps-3'>
                    <h4 className="card-title mb-0">{data.name || "Unknown"}</h4>
                </CardHeader>
                <CardBody className='pt-2 pb-2'>
                    {data.sensors?.map((sensor, sensorIndex) => (
                    <dl className="row mb-0" key={sensorIndex}>
                        <dd className="col-sm-6 text-truncate">{StringTidy(sensor.genDesc) || "State"}</dd>
                        <dd className="col-sm-6 text-end">{SensorBadge({ data: sensor })}</dd>
                    </dl>
                    ))}
                </CardBody>
            </Card>
        </React.Fragment>
    )
};


const SensorList = ({data} : {data: SensorListResponse}) => {
    const filterAndProcessSensors = (sensors, regexPatterns: RegExp[], includeRegex: boolean, componentName?: string) => {
        return sensors?.filter(sensor => {
            const filteredStates = sensor.states.filter(
                state => state.key !== "UPPER_NON_CRITICAL_GOING_LOW" && state.key !== "UPPER_NON_CRITICAL_GOING_HIGH"
            );
    
            const hasValidStatesOrValue = filteredStates.length > 0 || sensor.value !== null;
            const matchesRegex = regexPatterns.some(regex => regex.test(sensor.genDesc));
    
            // Determine whether to include or exclude sensors based on regex match
            const shouldIncludeSensor = includeRegex ? matchesRegex : !matchesRegex;
            
            return hasValidStatesOrValue && shouldIncludeSensor;
        }).map(sensor => ({
            ...sensor,
            // If componentName is provided and genDesc starts with it, remove it from genDesc
            genDesc: componentName && sensor.genDesc.startsWith(componentName) ? sensor.genDesc.slice(componentName.length).trim() : sensor.genDesc
        }));
    };
    

    const processedGroups = data.groups?.map(group => {
        const systemHealth = group.systemHealth?.map(health => ({
            ...health,
            states: health.states.filter(
                state => state.key !== "UPPER_NON_CRITICAL_GOING_LOW" && state.key !== "UPPER_NON_CRITICAL_GOING_HIGH"
            )
        }));
    
        const DISKS_REGEX = /\b(driveslotbay)\b/i;
        const ENERGY_REGEX = /\b(meter|pwrmeter|consumption)\b/i;
    
        const regexPatterns = [DISKS_REGEX, ENERGY_REGEX];

        const sensors = filterAndProcessSensors(group.sensors, regexPatterns, false);

        const diskSensors = filterAndProcessSensors(group.sensors, [DISKS_REGEX], true);
        const energySensors = filterAndProcessSensors(group.sensors, [ENERGY_REGEX], true);

        const components = group.components?.map(component => ({
            ...component,
            sensors: filterAndProcessSensors(component.sensors, regexPatterns, false, component.name)
        }));
    
        return { ...group, systemHealth, sensors, diskSensors, energySensors, components };
    });
    return (
        <React.Fragment>
            
                {processedGroups?.map((group, groupIndex) => (
                    <React.Fragment key={groupIndex}>

                        {group.systemHealth?.length !== 0 ? (
                        <Card className="mb-2">
                            <CardHeader className='p-2 ps-3'>
                                <h4 className="card-title mb-0">System Health</h4>
                            </CardHeader>
                            <CardBody className='pt-2 pb-1'>
                                {group.systemHealth?.map((sensor) => (
                                <dl className="row mb-0" key={sensor.slug}>
                                    <dd className="col-sm-6 text-truncate">{StringTidy(sensor.genDesc) || "State"}</dd>
                                    <dd className="col-sm-6 text-end">{SensorBadge({ data: sensor })}</dd>
                                </dl>
                                ))}
                            </CardBody>
                        </Card>
                        ):(null)}

                        {group.components?.length !== 0 ? (
                        <Card className="mb-2">
                            <CardHeader className='p-2 ps-3'>
                                <h4 className="card-title mb-0">Components</h4>
                            </CardHeader>
                            <CardBody className='pt-2 pb-1'>
                                <dl className="row mb-0">
                                    {group.components?.map((component, index) => (
                                    <React.Fragment key={`component_${index}`}>
                                    <dt className="col-sm-3 mb-2 border-bottom border-bottom-dashed">{component.name}</dt>
                                    <dd className="col-sm-9 mb-2 border-bottom border-bottom-dashed">
                                        {component.sensors?.map((sensor, index) => (
                                            <dl className="row mb-0" key={`component_sensor_${index}`}>
                                                <dd className="col-sm-6 text-truncate">{StringTidy(sensor.genDesc) || "State"}</dd>
                                                <dd className="col-sm-6 text-end">{SensorBadge({ data: sensor })}</dd>
                                            </dl>
                                        ))}
                                    </dd>
                                    </React.Fragment>
                                    ))}
                                </dl>
                            </CardBody>
                        </Card>
                        ):(null)}


                        {group.energySensors?.length !== 0 ? (
                        <Card className="mb-2">
                            <CardHeader className='p-2 ps-3'>
                                <h4 className="card-title mb-0">Energy</h4>
                            </CardHeader>
                            <CardBody className='pt-2 pb-1'>
                                {group.energySensors?.map((sensor, index) => (
                                <dl className="row mb-0" key={`energy_sensor_${index}`}>
                                    <dd className="col-sm-6 text-truncate">{StringTidy(sensor.genDesc) || "State"}</dd>
                                    <dd className="col-sm-6 text-end">{SensorBadge({ data: sensor })}</dd>
                                </dl>
                                ))}
                            </CardBody>
                        </Card>
                        ):(null)}
                        

                        {group.sensors?.length !== 0 ? (
                        <Card className="mb-2">
                            <CardHeader className='p-2 ps-3'>
                                <h4 className="card-title mb-0">Sensors</h4>
                            </CardHeader>
                            <CardBody className='pt-2 pb-1'>
                                {group.sensors?.map((sensor, index) => (
                                <dl className="row mb-0" key={`sensors_sensor_${index}`}>
                                    <dd className="col-sm-6 text-truncate">{StringTidy(sensor.genDesc) || "State"}</dd>
                                    <dd className="col-sm-6 text-end">{SensorBadge({ data: sensor })}</dd>
                                </dl>
                                ))}
                            </CardBody>
                        </Card>
                        ):(null)}
                        

                        

                        {group.diskSensors?.length !== 0 ? (
                            <React.Fragment>
                                <Card className="mb-2">
                                    <CardHeader className='p-2 ps-3'>
                                        <h4 className="card-title mb-0">Disk Bays</h4>
                                    </CardHeader>
                                    <CardBody className='pt-2 pb-1'>
                                        {group.diskSensors?.map((sensor, sensorIndex) => (
                                        <dl className="row mb-0" key={sensorIndex}>
                                            <dd className="col-sm-6 text-truncate">{StringTidy(sensor.genDesc) || "Unknown"}</dd>
                                            <dd className="col-sm-6 text-end">{SensorBadge({ data: sensor })}</dd>
                                        </dl>
                                    ))}
                                    </CardBody>
                                </Card>
                            </React.Fragment>
                        ):(null)}

                    
                    </React.Fragment>
                ))}
        </React.Fragment>
    )
};

const HypervisorSensors = () => {
    const hypervisorId = useParams().id;
    const { data, error, isLoading } = useGetHostSensorList(hypervisorId);
    return (
        <React.Fragment>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
            <React.Fragment>  
                <div className='bg-light-subtle p-2'>
                    {data.groups.filter(group => group.groupType === SensorGroupType.HOST_IPMI).length !== 0 ? (
                        <SensorList data={{ ...data, groups: data.groups.filter(group => group.groupType === SensorGroupType.HOST_IPMI) }} />
                    ):(
                        <CardBody className='p-5 mt-5 mb-5 text-center'>

                            <Button disabled outline color="secondary" className="btn-label"><i className="lab las la-microchip label-icon"></i> No IPMI Found</Button>

                        </CardBody>
                    )}
                </div>
            </React.Fragment> 
            )}
        </React.Fragment>
    )
};

export default HypervisorSensors;