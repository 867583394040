import React from 'react';

import {
    Card,
    CardBody,
    Col,
    Row,

} from "reactstrap"

import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { round } from 'lodash';

const VirtualMachineSidebar = ({ data }) => {
    const virtualMachine = data

    const { t } = useTranslation(['translation'])

    return (
        <React.Fragment>
            <Col style={{ flex: '0 0 300px' }}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div className="avatar-sm flex-shrink-0">
                                {virtualMachine.status === "running" ? (
                                    <span className="avatar-title bg-success-subtle rounded-circle fs-2">
                                        <FeatherIcon icon="play" className="text-success"/>
                                    </span>
                                ) : virtualMachine.status === "submitted" ? (
                                    <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                                        <FeatherIcon icon="clock" className="text-primary"/>
                                    </span>
                                ) : (
                                    <span className="avatar-title bg-danger-subtle rounded-circle fs-2">
                                        <FeatherIcon icon="square" className="text-danger"/>
                                    </span>
                                )}
                        
                            </div>

                            <div className="ms-3">
                                <h5 className="fs-16 mb-2">{virtualMachine.name}</h5>
                                <p className="text-muted mb-0">
                                    {t(`translation:virtualMachine:status:${virtualMachine.status}`)}
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <div>
                            <h4 className="card-title mb-3"><i className="lab las la-info-circle"></i> Info</h4>
                            <Row>
                                <div>
                                    <p className="text-muted mb-2">Description</p>
                                    <p>{virtualMachine.description}</p>
                                </div>

                                <div>
                                    <p className="text-muted mb-2">Hypervisor</p>
                                    <Link to={"/hypervisors/" + virtualMachine.host.slug}>{virtualMachine.host.name}</Link>
                                </div>
                            </Row>
                        </div>
                    </CardBody>
                    <CardBody className='card-header card-footer border-top-dashed border-bottom-dashed'>
                        <div>
                            <h4 className="card-title mb-3"><i className="lab las la-microchip"></i> Hardware</h4>
                            <Row>
                                <div>
                                    <p className="text-muted mb-2">Memory</p>
                                    {/* TODO Replace this with helper */}
                                    <p>{round(virtualMachine.memoryMb / 1024,2)} GB</p>
                                    <p className="text-muted mb-2">CPUs</p>
                                    <p>{virtualMachine.cpus}</p>
                                </div>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </Col>
        </React.Fragment>
    )
};

export default VirtualMachineSidebar;