import React from "react";
import { Link } from "react-router-dom";

const Name = (cell) => {
    return (
        <React.Fragment>
            <Link to={cell.row.original.slug} className="fw-medium link-primary">{cell.getValue()}</Link>
        </React.Fragment>
    );
};

const Status = (cell) => {
    return (
        <React.Fragment>
            {cell.getValue() === "Online" ?
                <span className="badge bg-success-subtle text-success text-uppercase">{cell.getValue()}</span>
                :
                cell.getValue() === "Offline" ?
                    <span className="badge bg-danger-subtle text-danger text-uppercase">{cell.getValue()}</span>
                    : null
            }
        </React.Fragment>
    );
};

const VirtualMachines = (cell) => {
    return (
        <React.Fragment>
            <span className="badge border border-primary text-primary me-2">{cell.row.original.vmCountTotal} Deployed</span>
            {cell.row.original.online ? (
                <span className="badge border border-success text-success">{cell.row.original.vmCountRunning} Running</span>
            ):(null)}
        </React.Fragment>
    );
};

export { Name, Status, VirtualMachines};
