import React from "react";
import { LocalDataTimeString } from "../GalliumTimeHelpers";

const ColLocalDateTime = (cell) => {
    return (
        <React.Fragment>
            {LocalDataTimeString(cell.getValue())}
        </React.Fragment>
    );
};

export default ColLocalDateTime