import React, { useEffect, useState, createContext } from "react"

import { Modal } from "reactstrap";

import Success from "./MFAModalSuccess";
import TOTPConfirm from "./MFAModalTOTPConfirm";
import RecoveryCodes from "./MFAModalRecoveryCodes";
import Enroll from "./MFAModalEnroll";
import { CreateMfaRecoveryCodesResponse, CreateMfaResponseBase, CreateMfaSuccessResponse, CreateMfaTotpRequiredResponse } from "generated";

interface ModalContextProps {
    response: CreateMfaResponseBase | CreateMfaRecoveryCodesResponse | CreateMfaSuccessResponse | CreateMfaTotpRequiredResponse;
    setResponse: (response: CreateMfaResponseBase) => void; 
    closeModal: () => void;
}

export const ModalContext = createContext<ModalContextProps | undefined>(undefined);

interface MFAModalProps {
    showModal: boolean;
    closeModal: () => void;
}

const MFAModal: React.FC<MFAModalProps> = ({ showModal, closeModal }) => {

    const [response, setResponse] = useState<CreateMfaResponseBase>(undefined)

    useEffect(() => {
        if (!showModal) {
            setResponse(undefined);
        }
    }, [showModal]);

    const ModalBodyComponent = () => {
        // @ts-ignore
        switch(response?.result) {
        case "SUCCESS":
            return <Success />
        case "CONFIRM_REQUIRED":
            return <TOTPConfirm />
        case "RECOVERY_CODES":
            return <RecoveryCodes />        
        default:
            return <Enroll  />
        }
    }

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true} backdrop={'static'}>
            <ModalContext.Provider value={{ response, setResponse, closeModal }}>
                <ModalBodyComponent />
            </ModalContext.Provider>
        </Modal>
    );
};

export default MFAModal;
