import React, { useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from "formik";
import { CreateOrgRequest, GalliumApiErrorResponse } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useCreateNewOrg } from 'GalliumAPIHooks/Org/OrgHooks';

const ServiceProviderAddChildOrg = () => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useCreateNewOrg()

    const [errorObject, setErrorObject] = useState(null)

    const handleCreateChildOrgFail = (error: GalliumApiErrorResponse) => {
        createChildOrgFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleCreateChildOrgSuccess = () => {
        toast.info("Child Organisation Created")
    }

    const handleCreateChildOrgRequest = (values: CreateOrgRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleCreateChildOrgFail(error)
            },
            onSuccess() {
                handleCreateChildOrgSuccess()
            }
        }
        setErrorObject(null)
        trigger(values, options);
    }

    const createChildOrgFormik = useFormik({
        initialValues: {
            slug: '',
            orgName: '',
            childOrg: true
        },
        validationSchema: Yup.object({
            orgName: Yup.string().required("Please provide and organisation name"),
            slug: Yup.string()
                .max(32, "Organization name must be at most 32 characters long")
                .matches(/^[^\s]+$/, "Organization name cannot contain spaces")
                .required("Please provide and organisation short name"),
        }),
        onSubmit: (values) => {
            handleCreateChildOrgRequest(values)
        },
    });


    document.title = t("serviceProvider.orgs.add") + " | Gallium";
    const crumbs = [
        {
            name: t("serviceProvider.orgs.title"),
            link: "/serviceprovider/org"
        },
        {
            name: t("serviceProvider.orgs.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("serviceProvider.orgs.add")} crumbs={crumbs} />
                    <FormikProvider value={createChildOrgFormik}>
                        <Card>
                            <CardBody>
                                <ErrorAlert errorObj={errorObject} />
                                <GalliumFormHeader icon="info" title={t("serviceProvider.orgs.formHeader")}>
                                    <div className="mb-3">
                                        <Field
                                            id="orgNameField"
                                            name="orgName"
                                            type="text"
                                            label="Name"
                                            placeholder="Enter an organisation name"
                                            component={GalliumInput}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Field
                                            id="slugField"
                                            name="slug"
                                            type="text"
                                            label="Short Name"
                                            placeholder="Must be unique and contain no spaces"
                                            component={GalliumInput}
                                        />
                                    </div>
                                </GalliumFormHeader>
                                <div className="float-end">
                                    <GalliumSubmitButton formik={createChildOrgFormik} spinner={isMutating} color="success">
                                        Create
                                    </GalliumSubmitButton>
                                </div>
                            </CardBody>
                        </Card>

                    </FormikProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ServiceProviderAddChildOrg;