import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Card, CardBody, Container, Col } from 'reactstrap';

import * as Yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";
import { AlertProfileAddOrUpdateRequest, GalliumApiErrorResponse, AlertProfile } from 'generated';
import { toast } from 'react-toastify';
import { GalliumFormHeader, GalliumInput, GalliumSubmitButton } from 'Components/Gallium/GalliumForms';
import ErrorAlert from 'Components/Gallium/ErrorHelper';

import { useNewAlert } from 'GalliumAPIHooks/Monitoring/MonitoringHooks';
import AlertProfilesWebhooksComponent from './WebhooksComponent';
import AlertProfilesExistingUserComponent from './ExistingUserComponent';
import AlertProfilesRemindersComponent from './RemindersComponent';
import AlertProfilesNotificationEventsComponent from './NotificationEventsComponent';
import AlertProfilesRulesEventsComponent from './AlertRulesComponent';
import AlertProfilesEmailComponent from './EmailComponent';
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import { useTranslation } from 'react-i18next';

const AddAlertProfile = () => {
    const {t} = useTranslation()
    const { trigger, isMutating } = useNewAlert()
    const navigate = useNavigate();

    const [errorObject, setErrorObject] = useState(undefined)

    const handleAddAlertProfileFail = (error: GalliumApiErrorResponse) => {
        addAlertProfileFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAddAlertProfileSuccess = (response: AlertProfile) => {
        toast.success("Alert Profile Created")
        navigate(`/settings/alerts/${response.slug}`);
    }

    const handleAddAlertProfileRequest = (values: AlertProfileAddOrUpdateRequest) => {
        const options = {
            onError(error: GalliumApiErrorResponse) {
                handleAddAlertProfileFail(error)
            },
            onSuccess(response: AlertProfile ) {
                handleAddAlertProfileSuccess(response)
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const addAlertProfileFormik = useFormik({
        initialValues: {
            name: '',
            emails: [],
            webhookUrls: [],
            webhookVerifySsl: true,
            userSlugs: [],
            alertRules: [],
            notifyEvent: [],
            reminderThresholdMinutes: 0
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please Enter a Name"),
            
            emails: Yup.array()
                .of(Yup.string().email("Invalid email address"))
                .nullable(),
            
            webhookUrls: Yup.array()
                .of(Yup.string().url("Invalid URL"))
                .nullable(),

            userSlugs: Yup.array()
                .of(Yup.string())
                .nullable(),

            alertRules: Yup.array()
                .of(Yup.object().shape({
                    // ... you can define individual field validations here if needed
                }))
                .min(1, "One alert rule must be selected")
                .required("One alert rule must be selected"),

            reminderThresholdMinutes: Yup.number()
        }),
        onSubmit: (values) => {
            handleAddAlertProfileRequest(values)
        },
    });

    document.title = "Add Alert Profile | Gallium";
    const crumbs = [
        {
            name: t("mainNav.settings"),
        },
        {
            name: t("alertProfiles.title"),
            link: "/settings/alerts"
        },
        {
            name: t("alertProfiles.add"),
        }
    ]
    return (    
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <GalliumBreadcrumb title={t("alertProfiles.add")} crumbs={crumbs} />
                    <Row>
                        <Col lg={12}>
                            <FormikProvider value={addAlertProfileFormik}>
                                <Card>
                                    <CardBody>
                                        <ErrorAlert errorObj={errorObject} />
                                
                                        <GalliumFormHeader icon="alert-circle" title="Alert Profile Settings">
                                            <Col md={6} className="mb-3">
                                                <Field
                                                    name="name"
                                                    id="name"
                                                    placeholder="Please enter a name for this alert profile"
                                                    type="text"
                                                    label="Name"
                                                    component={GalliumInput}
                                                />
                                            </Col>
                                            <AlertProfilesRulesEventsComponent />
                                        </GalliumFormHeader>
                                            
                                        <GalliumFormHeader icon="send" title="Notifications">
                                            <AlertProfilesExistingUserComponent />
                                            <AlertProfilesWebhooksComponent />
                                            <AlertProfilesEmailComponent />
                                            <AlertProfilesRemindersComponent />
                                            <AlertProfilesNotificationEventsComponent />
                                        </GalliumFormHeader>
                                    
                                        <GalliumSubmitButton className="float-end" formik={addAlertProfileFormik} spinner={isMutating} color="success">
                                            Add Alert Profile
                                        </GalliumSubmitButton>
                                    </CardBody>
                                </Card>
                            </FormikProvider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddAlertProfile;